import {useUserRole} from "../../../../app/hooks/useUserRole";
import * as React from "react";
import {useMemo} from "react";
import {translateKey} from "../../../../app/thunks/translations/translateKey";
import {ITranslationEntry} from "../../../../shared/models/ITranslationEntry";
import {useAppSelector} from "../../../../app/hooks/useAppSelector";
import {useAppDispatch} from "../../../../app/hooks/useAppDispatch";
import {usePopulateTranslationsDialog} from "../../../populate-translations/hooks/usePopulateTranslationsDialog";
import {useCopyTranslationsDialog} from "../../../copy-translations/hooks/useCopyTranslationsDialog";
import {useEditKeyDialog} from "../../../edit-key/hooks/useEditKeyDialog";
import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ContentCopy from "@mui/icons-material/ContentCopy";
import EditIcon from '@mui/icons-material/Edit';
import TranslateIcon from '@mui/icons-material/Translate';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {useEditTagsDialog} from "../../../tags/hooks/useEditTagsDialog";
import TagIcon from '@mui/icons-material/Tag';
import {UserRole} from "../../../../shared/models/userRole";
import {useTagStatusProvider} from "../../../tags/hooks/useTagStatusProvider";
import {TranslationTagStatus} from "../../../../shared/models/TranslationTagStatus";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {useTranslationCheckActions} from "../../hooks/useTranslationCheckActions";
import {TagNames} from "../../../tags/TagNames";

type EntryActionsProps = {
    sourceValue: string
    targetLanguage: string
    entry: ITranslationEntry
}

export const EntryActions = ({sourceValue, targetLanguage, entry}: EntryActionsProps) => {
    const populateDialogContext = usePopulateTranslationsDialog();
    const copyDialogContext = useCopyTranslationsDialog();
    const editKeyDialogContext = useEditKeyDialog();
    const editTagsDialogContext = useEditTagsDialog();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [userRole] = useUserRole();
    const isAdmin = userRole === UserRole.Admin;
    const userLanguages = useAppSelector(state => state.settings.user.languages);
    const allLanguagesAvailable = useAppSelector(state => state.settings.user.allLanguagesAvailable);
    const dispatch = useAppDispatch();
    const tagStatusProvider = useTagStatusProvider(entry.id, entry.tagStates);
    const showCheckActions = useMemo(() => {
        return isAdmin && Object.keys(userLanguages).some(l => tagStatusProvider(l)[TagNames.ToCheck]?.status === TranslationTagStatus.Active);
    }, [isAdmin, userLanguages, tagStatusProvider]);
    const [acceptChanges, rejectChanges] = useTranslationCheckActions(entry.id, undefined, entry.tagStates);

    const editEnabled = isAdmin;
    const copyEnabled = isAdmin && !!Object.keys(entry.values).length;
    const populateEnabled = isAdmin && entry.canBePopulated && allLanguagesAvailable;

    const translateEnabled = !!sourceValue && isAdmin && !entry.values[targetLanguage]?.value;
    const translateAllEnabled = !!sourceValue && isAdmin && Object.keys(userLanguages).some(l => !entry.values.hasOwnProperty(l)
        || !entry.values[l]
        || !entry.values[l].value);
    const editTagsEnabled = isAdmin;

    const menuButtonId = `${entry.id}-${targetLanguage}_context-menu-button`;
    const menuId = `${entry.id}-${targetLanguage}_context-menu`;

    return (
        <div>
            <IconButton id={menuButtonId}
                        aria-controls={open ? menuId : undefined}
                        aria-haspopup="true"
                        sx={{padding: 0}}
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                <MoreHorizIcon/>
            </IconButton>

            <Menu
                id={menuId}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': menuButtonId
                }}
            >
                {editEnabled &&
                    <MenuItem onClick={() =>
                        editKeyDialogContext.show({
                            currentKey: {
                                id: entry.id,
                                key: entry.key,
                                description: entry.description,
                                accessLevel: entry.keyAccessLevel
                            }
                        })}>
                        <ListItemIcon>
                            <EditIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Edit Key
                        </ListItemText>
                    </MenuItem>}

                {copyEnabled &&
                    <MenuItem onClick={() => copyDialogContext.show({sourceId: entry.id})}>
                        <ListItemIcon>
                            <ContentCopy fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Copy Translations
                        </ListItemText>
                    </MenuItem>}

                {populateEnabled &&
                    <MenuItem onClick={() => populateDialogContext.show({
                        sourceValue,
                        entry
                    })}>
                        <ListItemIcon>
                            <ManageSearchIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Populate Translations
                        </ListItemText>
                    </MenuItem>}

                {translateEnabled &&
                    <MenuItem onClick={() => dispatch(translateKey({id: entry.id, key: entry.key, languageCode: targetLanguage}))}>
                        <ListItemIcon>
                            <TranslateIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Translate
                        </ListItemText>
                    </MenuItem>}

                {translateAllEnabled &&
                    <MenuItem onClick={() => dispatch(translateKey({id: entry.id, key: entry.key}))}>
                        <ListItemIcon>
                            <TranslateIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Translate All
                        </ListItemText>
                    </MenuItem>}

                {editTagsEnabled &&
                    <MenuItem onClick={() => editTagsDialogContext.show({keyId: entry.id})}>
                        <ListItemIcon>
                            <TagIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Edit Tags
                        </ListItemText>
                    </MenuItem>}

                {showCheckActions &&
                    <MenuItem onClick={() => acceptChanges()}>
                        <ListItemIcon>
                            <ThumbUpIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Accept All
                        </ListItemText>
                    </MenuItem>}

                {showCheckActions &&
                    <MenuItem onClick={() => rejectChanges()}>
                        <ListItemIcon>
                            <ThumbDownIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Reject All
                        </ListItemText>
                    </MenuItem>}
            </Menu>
        </div>
    )
}
