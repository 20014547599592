import style from "./localization-key.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import * as React from "react";

type LocalizationKeyProps = {
    text: string
    title?: string
}

export const LocalizationKey = ({title, text}: LocalizationKeyProps) => {
    return (
        <div className={style.container}>
            <CopyToClipboard text={text}>
                <label className={style.key} title={title} onClick={e => e.stopPropagation()}>{text}</label>
            </CopyToClipboard>
        </div>
    )
}
