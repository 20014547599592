import * as React from "react";
import {ChangeEvent, useState} from "react";
import style from "./auth-section.module.scss";
import {sha256} from 'js-sha256';
import {APP_TITLE} from "../../../shared/constants";
import {authenticate} from "../../../app/thunks/user/authenticate";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {useAppDispatch} from "../../../app/hooks/useAppDispatch";

export const AuthSection = () => {
    const dispatch = useAppDispatch();

    const error = useAppSelector(state => state.user.errorMessage);

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const onSubmit = () => dispatch(authenticate({credentials: {username, passwordHash: sha256(password)}}));
    const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => setUsername(event.target.value);
    const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    const renderForm = () => (
        <form className={style.authForm}>
            <input type="text"
                   className={style.loginInput}
                   value={username}
                   placeholder="Login"
                   required={true}
                   autoComplete="username"
                   onChange={onUsernameChange}/>

            <input type="password"
                   className={style.passwordInput}
                   value={password}
                   placeholder="Password"
                   autoComplete="current-password"
                   onChange={onPasswordChange}/>

            <button type="submit"
                    className={style.loginButton}
                    onClick={onSubmit}>Sign in
            </button>
        </form>
    )

    return (
        <section className={style.authPage}>
            <h4 className={style.title}>{APP_TITLE}</h4>
            {error && <div className={style.error}>{error}</div>}
            {renderForm()}
        </section>
    )
}
