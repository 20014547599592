import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {useCallback, useMemo} from "react";
import {ITranslationEntryTagState} from "../../../shared/models/ITranslationEntryTagState";
import {TagViewState} from "./tagViewState";
import {useAppDispatch} from "../../../app/hooks/useAppDispatch";
import {switchTagStatus, toggleTag, updateTags} from "../../../app/reducers/changesReducer";
import {useTagViewStateProvider} from "./useTagViewStateProvider";
import {TranslationTagStatus} from "../../../shared/models/TranslationTagStatus";

const selectState = (viewStates: [TagViewState[], Record<string, TagViewState[]>], languageCode?: string) =>
    languageCode ? viewStates[1][languageCode] : viewStates[0];

export const useTranslationTags = (keyId: number, tagStates?: ITranslationEntryTagState[]): [
    [TagViewState[], Record<string, TagViewState[]>],
    ((tagNames: string[], languageCode?: string) => void),
    ((tagName: string, languageCode?: string) => void),
    (tagName: string, status: TranslationTagStatus, languageCode?: string) => void
] => {
    const dispatch = useAppDispatch();
    const viewStateProvider = useTagViewStateProvider();

    const languages = useAppSelector(state => state.settings.user.languages);

    const viewStates = useMemo(() => {
        const result: [TagViewState[], Record<string, TagViewState[]>] = [[], {}];

        result[0].push(...viewStateProvider(keyId, tagStates))

        for (const languageCode of Object.keys(languages)) {
            (result[1][languageCode] || (result[1][languageCode] = [])).push(...viewStateProvider(keyId, tagStates, languageCode));
        }

        return result;
    }, [keyId, languages, tagStates, viewStateProvider]);

    const onChange = useCallback((viewableTags: string[], languageCode?: string) => {
        dispatch(updateTags({
            viewableTags,
            viewStates: selectState(viewStates, languageCode),
            languageCode,
            keyId
        }))
    }, [dispatch, keyId, viewStates]);

    const onToggle = useCallback((tagName: string, languageCode?: string) => {
        dispatch(toggleTag({
            viewState: selectState(viewStates, languageCode).filter(x => x.name === tagName)[0],
            keyId,
            languageCode
        }));
    }, [dispatch, keyId, viewStates]);

    const onSwitch = useCallback((tagName: string, status: TranslationTagStatus, languageCode?: string) => {
        dispatch(switchTagStatus({
            viewState: selectState(viewStates, languageCode).filter(x => x.name === tagName)[0],
            keyId,
            languageCode,
            status
        }));
    }, [dispatch, keyId, viewStates]);

    return [viewStates, onChange, onToggle, onSwitch];
}
