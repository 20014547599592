import {DialogContainer} from "../../dialogs/components/DialogContainer";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ILocalizationTranslation} from "../../../shared/models/ILocalizationTranslation";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {useApiClient} from "../../../shared/api/hooks/useApiClient";
import {DialogCloseButton} from "../../dialogs/components/DialogCloseButton";
import {changeEntries} from "../../../app/reducers/changesReducer";
import {PopulateMode} from "../../../shared/models/populateMode";
import {usePopulateTranslationsDialog} from "../hooks/usePopulateTranslationsDialog";
import {TextEditor} from "../../translations/components/text-editor/TextEditor";
import {LocalizationKey} from "../../translations/components/entry/LocalizationKey";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {useAppDispatch} from "../../../app/hooks/useAppDispatch";
import {LanguageSelector} from "../../../shared/components/forms/LanguageSelector";

export const PopulateTranslationsDialog = () => {
    const dispatch = useAppDispatch();
    const {onShowSubscription, onHideSubscription, ...context} = usePopulateTranslationsDialog();
    const apiClient = useApiClient();

    const projects = useAppSelector(state => state.settings.user.projects);
    const languages = useAppSelector(state => state.settings.project.languages);
    const project = useAppSelector(state => state.view.project);

    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [populateOptions, setPopulateOptions] = useState<Record<number, ILocalizationTranslation[]>>({});
    const [selectedPopulateOption, setSelectedPopulateOption] = useState<number>(0);

    const [viewableLanguages, setViewableLanguages] = useState<string[]>([]);

    useEffect(() => onHideSubscription(() => {
        setError(undefined);
        setLoading(false);
        setPopulateOptions({});
        setSelectedPopulateOption(0);
    }), [onHideSubscription]);

    useEffect(() => onShowSubscription(dialogData => {
        if (!dialogData) {
            return;
        }

        setLoading(true);
        setError(undefined);

        apiClient.current.getPopulateOptions(project, dialogData.sourceValue)
            .then(options => {
                const sortedOptions = Object
                    .entries(options)
                    .sort((a, b) => a[1][0].key.localeCompare(b[1][0].key));

                if (sortedOptions.length) {
                    setPopulateOptions(options);
                    setSelectedPopulateOption(Number(sortedOptions[0][0]));
                } else {
                    setError('No translations');
                }
            })
            .catch(error => setError(error.message))
            .finally(() => setLoading(false))
    }), [apiClient, onShowSubscription, project]);

    const dialogData = context.getData();

    const submit = (overwrite: boolean) => {
        const entry = dialogData.entry;

        const updates = populateOptions[selectedPopulateOption].map(x => ({
            project: entry.project,
            id: entry.id,
            key: entry.key,
            populateMode: PopulateMode.existing,
            languageCode: x.languageCode,
            value: x.value
        }));

        if (overwrite) {
            dispatch(changeEntries({updates}));
        } else {
            dispatch(changeEntries({updates: updates.filter(u => !entry.values?.[u.languageCode]?.value?.trim()?.length)}))
        }

        context.hide();
    };

    const renderAlerts = () => (
        <React.Fragment>
            {error && <Grid item><Alert severity="error">{error}</Alert></Grid>}
        </React.Fragment>
    )

    const renderTextArea = (value: string) => (
        <TextEditor value={value} width='100%' minHeight={'35px'} readOnly/>
    )

    const renderLoading = () => (
        <Box sx={{display: 'flex'}}>
            <CircularProgress/>
        </Box>
    )

    const renderPopulateOptions = () => (
        <React.Fragment>
            {!loading && !!selectedPopulateOption &&
                <FormControl fullWidth>
                    <FormLabel id="populate-options" sx={{paddingY: 2}}>Options</FormLabel>
                    <RadioGroup aria-labelledby="populate-options"
                                name="populate-options-radiogroup"
                                value={selectedPopulateOption}
                                onChange={event => setSelectedPopulateOption(Number(event.target.value))}
                    >
                        {Object
                            .keys(populateOptions)
                            .map(o => Number(o))
                            .map(id => ({
                                id,
                                key: populateOptions[id][0].key,
                                project: populateOptions[id][0].project.toLowerCase(),
                                options: populateOptions[id]
                            }))
                            .sort((a, b) => a.key.localeCompare(b.key))
                            .map(x => {
                                const {
                                    id,
                                    key,
                                    project,
                                    options
                                } = x;

                                const diffLanguages: string[] = [];
                                if (id !== selectedPopulateOption) {
                                    const selectedOptions = populateOptions[selectedPopulateOption];

                                    for (const option of options) {
                                        const value = selectedOptions.filter(x => x.languageCode === option.languageCode)[0]?.value;
                                        if (option.value !== value) {
                                            diffLanguages.push(option.languageCode);
                                        }
                                    }
                                }

                                const translations = options
                                    .filter(x => viewableLanguages.includes(x.languageCode) || diffLanguages.includes(x.languageCode))
                                    .sort((a, b) => a.languageCode.localeCompare(b.languageCode))

                                return (
                                    <FormControlLabel control={<Radio/>}
                                                      key={id}
                                                      value={id}
                                                      sx={{
                                                          alignItems: 'flex-start',
                                                          '& .MuiFormControlLabel-label': {
                                                              width: '100%'
                                                          },
                                                          marginY: 2
                                                      }}
                                                      label={
                                                          <Accordion defaultExpanded onClick={e => e.preventDefault()}>
                                                              <AccordionSummary
                                                                  expandIcon={<ExpandMore/>}
                                                                  aria-controls="panel1a-content"
                                                                  id="panel1a-header"
                                                              >
                                                                  <div style={{
                                                                      display: 'inline-flex',
                                                                      justifyContent: 'space-between',
                                                                      width: '100%'
                                                                  }}>
                                                                      <LocalizationKey text={key}/>

                                                                      <Typography>{projects[project] || project}</Typography>
                                                                  </div>
                                                              </AccordionSummary>

                                                              <AccordionDetails>
                                                                  <Stack spacing={4}>
                                                                      <div>
                                                                          <Typography>Available languages: {options.length - 1} out of {Object.keys(languages).length}</Typography>
                                                                      </div>

                                                                      {translations.map(x => (
                                                                          <div key={`${x.id}_${x.languageCode}`}>
                                                                              <Typography fontSize={'14px'} color={'#060606'}>
                                                                                  <strong>
                                                                                      {diffLanguages.includes(x.languageCode) &&
                                                                                          <span>! </span>
                                                                                      }
                                                                                      <small>{languages[x.languageCode]}</small>
                                                                                  </strong>
                                                                              </Typography>

                                                                              {renderTextArea(x.value)}
                                                                          </div>
                                                                      ))}
                                                                  </Stack>
                                                              </AccordionDetails>
                                                          </Accordion>
                                                      }/>
                                )
                            })}
                    </RadioGroup>
                </FormControl>
            }
        </React.Fragment>
    )

    return (
        <DialogContainer dialogName={context.dialogName} sx={{
            '& .MuiDialog-paper': !selectedPopulateOption
                ? {}
                : (
                    {
                        height: ['100vh', '80vh'],
                        maxHeight: ['none', '1024px']
                    }
                )
        }}>
            <DialogTitle>Populate Translations </DialogTitle>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent dividers>
                <Stack spacing={2}>
                    {renderAlerts()}

                    {loading || !dialogData ?
                        renderLoading()
                        : <React.Fragment>
                            <div>
                                <LanguageSelector onSelected={setViewableLanguages}/>
                            </div>
                            <div>
                                <LocalizationKey text={dialogData.entry.key}/>
                            </div>
                            <div>
                                {renderTextArea(dialogData.sourceValue)}
                            </div>
                            <div>
                                {renderPopulateOptions()}
                            </div>
                        </React.Fragment>}
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => submit(false)} disabled={loading || !selectedPopulateOption}>Populate</Button>
                <Button onClick={() => submit(true)} disabled={loading || !selectedPopulateOption}>Overwrite</Button>
            </DialogActions>
        </DialogContainer>
    )
}
