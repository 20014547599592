import {useEffect, useState} from "react";
import {LogHandler} from "../../logging/LogHandler";
import {useApiClient} from "../../../shared/api/hooks/useApiClient";

export type KeyOptionValue = { label: string, value: number, group: string };

export const useKeyOptions = (project: string | undefined, enabled: boolean): KeyOptionValue[] => {
    const apiClient = useApiClient();

    const [keyOptions, setKeyOptions] = useState<KeyOptionValue[]>([]);

    useEffect(() => {
        if (!project || !enabled) {
            return;
        }

        apiClient.current.listKeys(project)
            .then(keys => setKeyOptions(keys
                .map(x => ({label: x.value, value: x.key, group: x.value.split('.')[0]}))
                .sort((a, b) => {
                    const groupCompareResult = a.group.localeCompare(b.group);

                    if (groupCompareResult) {
                        return groupCompareResult;
                    }

                    return a.label.localeCompare(b.label);
                })
            ))
            .catch(e => LogHandler.error('useKeyOptions', 'failed to list keys', {e, project}));
    }, [apiClient, project, enabled]);

    return keyOptions;
}
