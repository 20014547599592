import {ILogger} from "./ILogger";
import {ILogRequest} from "./ILogRequest";
import {LogLevel} from "./LogLevel";

export class ConsoleLogger implements ILogger {
    private readonly consoleLog: Record<LogLevel, (message: string, trackingObject?: any) => void> = {
        ["Debug"]: (log: string, tracking?: any) => console.debug(log, tracking),
        ["Info"]: (log: string, tracking?: any) => console.info(log, tracking),
        ["Warn"]: (log: string, tracking?: any) => console.warn(log, tracking),
        ["Error"]: (log: string, tracking?: any) => console.error(log, tracking),
        ["Fatal"]: (log: string, tracking?: any) => console.error(log, tracking)
    };

    public log(request: ILogRequest): void {
        const logger = this.consoleLog[request.logLevel];

        if (logger) {
            logger(`[${request.logLevel}]\nCategory: ${request.category}\nMessage: ${request.message}`, request.trackingObject);
        } else {
            console.error("Console logger not found for level " + request.logLevel);
        }
    }
}
