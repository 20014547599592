import {useLayoutEffect} from "react";
import {useAppSelector} from "./useAppSelector";
import {useAppDispatch} from "./useAppDispatch";
import {getTranslations} from "../thunks/translations/getTranslations";

export const useTranslationsRequestWatcher = () => {
    const dispatch = useAppDispatch();
    const settingsLoaded = useAppSelector(state => state.settings.hasLoaded);
    const viewMode = useAppSelector(state => state.view.viewMode);
    const project = useAppSelector(state => state.view.project);
    const language = useAppSelector(state => state.view.language);
    const page = useAppSelector(state => state.view.page);
    const pageSize = useAppSelector(state => state.view.pageSize);
    const searchQuery = useAppSelector(state => state.view.searchQuery);
    const searchOptions = useAppSelector(state => state.view.searchOptions);

    useLayoutEffect(() => {
        if (settingsLoaded) {
            dispatch(getTranslations());
        }
    }, [settingsLoaded, viewMode, project, language, page, pageSize, searchQuery, searchOptions, dispatch]);
}
