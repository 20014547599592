export class Environment {
    public static isDevelopment(): boolean {
        return process.env.NODE_ENV === 'development';
    }

    public static isProduction(): boolean {
        return process.env.NODE_ENV === 'production' && !process.env.REACT_APP_ENV;
    }

    public static isAlpha(): boolean {
        return process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'alpha';
    }

    public static isBeta(): boolean {
        return process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'beta';
    }
}
