import {Box, Chip, FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/hooks/useAppSelector";

const LANGUAGE_ITEM_HEIGHT = 48;
const LANGUAGE_ITEM_PADDING_TOP = 8;

const LanguageMenuProps = {
    PaperProps: {
        style: {
            maxHeight: LANGUAGE_ITEM_HEIGHT * 4.5 + LANGUAGE_ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

type LanguageSelectorProps = {
    onSelected: (languages: string[]) => void
    includeSourceLanguage?: boolean
    sourceLanguageByDefault?: boolean
}

export const LanguageSelector = ({onSelected, includeSourceLanguage = false, sourceLanguageByDefault = false}: LanguageSelectorProps) => {
    const languages = useAppSelector(state => state.settings.project.languages);
    const defaultLanguage = useAppSelector(state => state.view.language);
    const sourceLanguage = useAppSelector(state => state.settings.user.sourceLanguage);

    const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
        includeSourceLanguage
        && sourceLanguageByDefault
        && defaultLanguage !== sourceLanguage
            ? [sourceLanguage, defaultLanguage]
            : [defaultLanguage]
    );

    useEffect(() => onSelected(selectedLanguages), [onSelected, selectedLanguages]);

    return (
        <FormControl fullWidth>
            <Select
                multiple
                MenuProps={LanguageMenuProps}
                value={selectedLanguages}
                onChange={(event: SelectChangeEvent<typeof selectedLanguages>) => {
                    const {target: {value}} = event;
                    setSelectedLanguages(typeof value === 'string' ? value.split(',') : value,);
                }}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
                        {selected.map((value) => (
                            <Chip key={`languages-chip_${value}`} label={languages[value]}/>
                        ))}
                    </Box>
                )}
            >
                {Object
                    .entries(languages)
                    .filter(l => includeSourceLanguage || l[0] !== sourceLanguage)
                    .sort((a, b) => a[1].localeCompare(b[1]))
                    .map(x => (
                        <MenuItem key={x[0]} value={x[0]}>{x[1]}</MenuItem>
                    ))}
            </Select>
            <FormHelperText>Languages</FormHelperText>
        </FormControl>
    )
};
