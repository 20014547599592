export const LOCALSTORAGE_KEYS = {
    AUTH: {
        JWT_TOKEN: "jwt"
    },
    SETTINGS: {
        PROJECT: "project",
        MODE: "mode",
        LANGUAGE: "language",
        SEARCH_QUERY: "search_query"
    }
};

export const DOM_EVENTS = {
    RESIZE: 'resize'
};

export const APP_TITLE = "XM Localizations";
