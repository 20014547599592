import {useApiClient} from "../../../shared/api/hooks/useApiClient";
import {useEffect, useState} from "react";
import {LogHandler} from "../../logging/LogHandler";
import {KeyOptionValue} from "./useKeyOptions";

export const useAppVersions = (project: string | undefined): KeyOptionValue[] => {
    const apiClient = useApiClient();

    const [keyOptions, setKeyOptions] = useState<KeyOptionValue[]>([]);

    useEffect(() => {
        if (!project) {
            return;
        }

        apiClient.current.listAppVersions(project)
            .then(keys => setKeyOptions(keys
                .map(x => {
                    const parts = x.value.split('.');

                    return ({label: x.value, value: x.key, group: parts[0] + '.' + parts[1]});
                })
                .sort((a, b) => {
                    const groupCompareResult = a.group.localeCompare(b.group);

                    if (groupCompareResult) {
                        return groupCompareResult;
                    }

                    return a.label.localeCompare(b.label);
                })
            ))
            .catch(e => LogHandler.error('useAppVersions', 'failed to list app versions', {e, project}));
    }, [apiClient, project]);

    return keyOptions;
}
