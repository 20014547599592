import style from "./entry.module.scss";
import * as React from "react";
import {Children} from "../../../../shared/types";
import {Accordion, AccordionDetails, AccordionSummary, Stack, Typography} from "@mui/material";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

type EntryCollapsibleContentProps = {
    renderTranslation: (className: string, languageCode: string) => Children
    languageCodes: string[]
}

export const EntryCollapsibleContent = ({renderTranslation, languageCodes}: EntryCollapsibleContentProps) => {
    return (
        <React.Fragment>
            {!!languageCodes?.length &&
                <Accordion disableGutters
                           elevation={0}
                           square
                           TransitionProps={{
                               timeout: 250,
                               unmountOnExit: true
                           }}
                           sx={{
                               border: 'none',
                               background: 'none',
                               padding: 0,
                               '&:before': {
                                   display: 'none'
                               }
                           }}>
                    <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
                                      sx={{
                                          flexDirection: 'row-reverse',
                                          minHeight: '30px',
                                          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                              transform: 'rotate(90deg)',
                                          },
                                          '& .MuiAccordionSummary-content': {
                                              marginLeft: 1,
                                              marginY: 0
                                          },
                                          padding: 0,
                                      }}>
                        <Typography>
                            <small>Show all languages</small>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding: 0}}>
                        <Stack paddingY={1} gap={2}>
                            {languageCodes
                                .map(l => renderTranslation(`${style.targetLanguage} ${style.languages}`, l))}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            }
        </React.Fragment>
    )
}
