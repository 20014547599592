import {TagViewState} from "../../tags/hooks/tagViewState";
import {IconButton, Stack} from "@mui/material";
import {revertTagChange} from "../../../app/reducers/changesReducer";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {ChangedTag} from "./ChangedTag";
import {useAppDispatch} from "../../../app/hooks/useAppDispatch";

type TagDiffProps = {
    tagState: TagViewState,
    keyId: number,
    languageCode?: string
}

export const TagDiff = ({tagState, keyId, languageCode}: TagDiffProps) => {
    const dispatch = useAppDispatch();

    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <ChangedTag tagState={tagState} variant={'original'}/>
            <ChangedTag tagState={tagState} variant={'current'}/>

            <IconButton onClick={() => dispatch(revertTagChange({tagState, keyId, languageCode}))}
                        sx={{'&:hover': {color: 'indianred'}}}
                        size="small">
                <DeleteIcon fontSize="inherit"/>
            </IconButton>
        </Stack>
    )
}
