import {ITranslationEntryTagState} from "../../../shared/models/ITranslationEntryTagState";
import {useMemo} from "react";
import {useTagStatusProvider} from "./useTagStatusProvider";
import {TagStatusContainer} from "../types/TagStatusContainer";

export const useTagStatuses = (keyId: number, languageCode: string, tagStates?: ITranslationEntryTagState[]): Record<string, TagStatusContainer> => {
    const tagStatusProvider = useTagStatusProvider(keyId, tagStates);

    return useMemo(() => tagStatusProvider(languageCode), [languageCode, tagStatusProvider]);
}

