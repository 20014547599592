import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {ITranslationEntry} from "../../../shared/models/ITranslationEntry";
import {getByKey} from "../../../shared/utils/getByKey";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {LogHandler} from "../../logging/LogHandler";
import {DialogContainer} from "../../dialogs/components/DialogContainer";
import {DialogContent, DialogTitle, Skeleton, Stack} from "@mui/material";
import {DialogCloseButton} from "../../dialogs/components/DialogCloseButton";
import {useApiClient} from "../../../shared/api/hooks/useApiClient";
import {useChangesDialog} from "../hooks/useChangesDialog";
import {groupBy} from "../../../shared/utils/groupBy";
import {KeyChangeItem} from "./KeyChangeItem";
import {ITranslationEntryUpdate} from "../../../shared/models/ITranslationEntryUpdate";
import {ITranslationTagStatusChangeRequest} from "../../../shared/models/ITranslationTagStatusChangeRequest";
import {useHasUnsavedChanges} from "../../../app/hooks/useHasUnsavedChanges";

export const ChangesDialog = () => {
    const context = useChangesDialog();
    const translationChanges = useAppSelector(state => state.changes.changes);
    const tagChanges = useAppSelector(state => state.changes.tagChanges);
    const hasChanges = useHasUnsavedChanges();
    const apiClient = useApiClient();

    const [entries, setEntries] = useState<ITranslationEntry[]>([]);

    const mergedChanges = useMemo(() => {
        const translationChangesMap = groupBy(Object.values(translationChanges), x => x.id);
        const tagChangesMap = groupBy(Object.values(tagChanges), x => x.identifier.keyId);

        const ids = new Set<number>([
            ...Object.keys(translationChangesMap).map(x => Number(x)),
            ...Object.keys(tagChangesMap).map(x => Number(x))
        ]);

        const result: {
            keyId: number,
            entry: ITranslationEntry,
            translationChanges: ITranslationEntryUpdate[],
            tagChanges: ITranslationTagStatusChangeRequest[]
        }[] = [];

        for (const id of ids) {
            result.push({
                keyId: id,
                entry: getByKey(entries, id),
                translationChanges: translationChangesMap?.[id],
                tagChanges: tagChangesMap?.[id]
            });
        }

        return result;
    }, [entries, tagChanges, translationChanges]);

    const shown = context.hasShown();

    useEffect(() => {
        shown && !hasChanges && context.hide();
    }, [context, hasChanges, shown]);

    useEffect(() => {
        if (!shown) {
            return;
        }

        const entryIds = new Set([
            ...Object
                .values(translationChanges)
                .map(ch => ch.id),
            ...Object
                .values(tagChanges)
                .map(ch => ch.identifier.keyId)
        ]);

        if (!entryIds.size) {
            return;
        }

        apiClient.current.getEntriesByIds([...entryIds])
            .then(e => e && setEntries(e))
            .catch(() => LogHandler.error('ChangesDialog', 'failed to get entries'));
        // TODO: Do something
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shown]);

    return (
        <DialogContainer dialogName={context.dialogName}>
            <DialogTitle>Changes</DialogTitle>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent dividers>
                <Stack spacing={3}>
                    {mergedChanges
                        .map(x => {
                            return (
                                <React.Fragment key={x.keyId}>
                                    {x.entry ?
                                        <KeyChangeItem keyId={x.keyId}
                                                       translationChanges={x.translationChanges}
                                                       entry={x.entry}/>
                                        : <Skeleton variant="text"
                                                    sx={{fontSize: '1rem'}}/>}
                                </React.Fragment>
                            )
                        })
                    }
                </Stack>
            </DialogContent>
        </DialogContainer>
    )
}

