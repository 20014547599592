import {ChangesDialog} from "../../features/changes-dialog/components/ChangesDialog";
import {EditKeyDialog} from "../../features/edit-key/components/EditKeyDialog";
import {CopyTranslationsDialog} from "../../features/copy-translations/components/CopyTranslationsDialog";
import {PopulateTranslationsDialog} from "../../features/populate-translations/components/PopulateTranslationsDialog";
import React from "react";
import {EditTagsDialog} from "../../features/tags/components/EditTagsDialog";
import {FullScreenEditorDialog} from "../../features/translations/components/entry/FullScreenEditorDialog";
import {ErrorAlertDialog} from "../../features/error-alert/components/ErrorAlertDialog";
import {ExportDataDialog} from "../../features/export-data/components/ExportDataDialog";
import {ImageAttachmentsDialog} from "../../features/edit-key/components/ImageAttachmentsDialog";

export const AppDialogs = () => {
    return (
        <React.Fragment>
            <ChangesDialog/>
            <EditKeyDialog/>
            <CopyTranslationsDialog/>
            <PopulateTranslationsDialog/>
            <EditTagsDialog/>
            <FullScreenEditorDialog/>
            <ErrorAlertDialog/>
            <ExportDataDialog/>
            <ImageAttachmentsDialog/>
        </React.Fragment>
    )
}
