import {useExportDataDialog} from "../hooks/useExportDataDialog";
import React, {useState} from "react";
import {DialogContainer} from "../../dialogs/components/DialogContainer";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {DialogCloseButton} from "../../dialogs/components/DialogCloseButton";
import {LanguageSelector} from "../../../shared/components/forms/LanguageSelector";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {ITranslationEntriesRequest} from "../../../shared/models/ITranslationEntriesRequest";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {format} from "date-fns";

export const ExportDataDialog = () => {
    const context = useExportDataDialog();

    const project = useAppSelector(state => state.view.project);
    const searchQuery = useAppSelector(state => state.view.searchQuery);
    const searchMode = useAppSelector(state => state.view.searchOptions.searchMode);
    const viewMode = useAppSelector(state => state.view.viewMode);
    const language = useAppSelector(state => state.view.language);
    const sourceLanguage = useAppSelector(state => state.settings.user.sourceLanguage);

    const token = useAppSelector(state => state.user.jwt);
    const apiClient = new LocalizationClient(token);

    const request: ITranslationEntriesRequest = {
        project: project,
        searchQuery: searchQuery,
        searchMode: searchMode,
        viewMode: viewMode,
        targetLanguage: language,
        sourceLanguage: sourceLanguage
    };

    const [languages, setLanguages] = useState<string[]>([]);

    const downloadFile = async (fullUrl: string) => {
        await fetch(fullUrl, {
            headers: {Authorization: `Bearer ${token}`}
        })
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                const date = new Date();
                link.setAttribute('download', `translations_${project}_${format(date, 'yyyyMMdd_HHmmss')}.xlsx`);
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
    }

    return (
        <DialogContainer dialogName={context.dialogName}>
            <DialogTitle>Export Data</DialogTitle>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent dividers>
                <LanguageSelector onSelected={setLanguages} includeSourceLanguage sourceLanguageByDefault/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => downloadFile(apiClient.getExportUrl(request, languages))}
                        disabled={!languages?.length}>Download</Button>
            </DialogActions>
        </DialogContainer>
    )
}
