import {ApiUrlBuilder} from "./ApiUrlBuilder";
import {ViewMode} from "../../models/viewMode";
import {SortDirection} from "../../models/sortDirection";
import {SearchMode} from "../../models/searchMode";

export class ApiGetEntriesUrlBuilder extends ApiUrlBuilder {
    public setSearchQuery(searchQuery?: string): this {
        if (searchQuery) {
            this.parameters["SearchQuery"] = searchQuery;
        }

        return this;
    }

    public setLimit(limit?: number): this {
        if (limit && limit > 0) {
            this.parameters["Limit"] = String(limit);
        }

        return this;
    }

    public setOffset(offset?: number): this {
        if (offset && offset > 0) {
            this.parameters["Offset"] = String(offset);
        }

        return this;
    }

    public setProject(project?: string): this {
        if (project) {
            this.parameters["Project"] = project;
        }

        return this;
    }

    public setSortDirection(sortDirection?: SortDirection): this {
        if (sortDirection) {
            this.parameters["SortDirection"] = String(sortDirection);
        }

        return this;
    }

    public setSearchMode(searchMode?: SearchMode): this {
        if (searchMode) {
            this.parameters["searchMode"] = String(searchMode);
        }

        return this;
    }

    public setViewMode(viewMode?: ViewMode): this {
        if (viewMode) {
            this.parameters["ViewMode"] = String(viewMode);
        }

        return this;
    }

    public setTargetLanguage(targetLanguage?: string): this {
        if (targetLanguage) {
            this.parameters["TargetLanguage"] = targetLanguage;
        }

        return this;
    }

    public setSourceLanguage(sourceLanguage?: string): this {
        if (sourceLanguage) {
            this.parameters["SourceLanguage"] = sourceLanguage;
        }

        return this;
    }

    public setLanguages(languages?: string[]): this {
        if (languages) {
            this.arrayParameters["Languages"] = languages;
        }

        return this;
    }
}
