import {useCallback, useRef} from "react";
import {Unsubscribe} from "./unsubscribe";
import {SubscriberCallback} from "./subscriberCallback";

export const useSubscription = <TProps = void>(subscribers: SubscriberCallback<TProps>[]): [(callback: SubscriberCallback<TProps>) => Unsubscribe, (props: TProps) => void] => {
    const subscribersRef = useRef(subscribers);

    const subscribe = useCallback((callback: SubscriberCallback<TProps>): Unsubscribe => {
        subscribersRef.current.push(callback);
        return () => {
            for (let i = 0, len = subscribersRef.current.length; i < len; ++i) {
                if (subscribersRef.current[i] === callback) {
                    subscribersRef.current.splice(i, 1);
                    return;
                }
            }
        }
    }, []);

    const notifySubscribers = useCallback((props: TProps) => {
        for (const callback of subscribersRef.current) {
            if (callback) {
                try {
                    callback(props);
                } catch (e) {
                    console.error('subscriber callback has failed')
                }
            }
        }
    }, []);

    return [subscribe, notifySubscribers];
}
