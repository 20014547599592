import {TagViewState} from "../../tags/hooks/tagViewState";
import {Chip} from "@mui/material";
import {TranslationTagStatus} from "../../../shared/models/TranslationTagStatus";
import React from "react";

type ChangedTagProps = {
    tagState: TagViewState,
    variant: 'original' | 'current'
}

export const ChangedTag = ({tagState, variant}: ChangedTagProps) => {
    const status = variant === 'original'
        ? tagState.originalStatus
        : tagState.status;

    return (
        <Chip label={tagState.displayName}
              disabled={status == null || status === TranslationTagStatus.Deleted}
              size={'small'}
              variant={'outlined'}
              sx={{width: '150px'}}
              color={
                  (
                      status === TranslationTagStatus.Active
                          ? 'primary'
                          : status === TranslationTagStatus.Inactive
                              ? 'error'
                              : 'default'
                  )
              }/>
    );
}
