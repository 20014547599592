import {IChangesState} from "../states/IChangesState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ITranslationEntryUpdate} from "../../shared/models/ITranslationEntryUpdate";
import {saveChanges} from "../thunks/changes/saveChanges";
import {addKey} from "../thunks/changes/addKey";
import {updateKey} from "../thunks/changes/updateKey";
import {getTagChangeId} from "../../features/tags/getTagChangeId";
import {TagViewState} from "../../features/tags/hooks/tagViewState";
import {TranslationTagStatus} from "../../shared/models/TranslationTagStatus";
import {ITranslationTagStateIdentifier} from "../../shared/models/ITranslationTagStateIdentifier";

const initialState: IChangesState = {
    changes: {},
    tagChanges: {},
    isLoading: false
}

const getChangeId = (id: number, languageCode: string) => `${id}_${languageCode}`.toLowerCase();

export const changesSlice = createSlice({
    name: 'changes',
    initialState,
    reducers: {
        changeEntries: (state, action: PayloadAction<{ updates: ITranslationEntryUpdate | ITranslationEntryUpdate[] }>) => {
            let updates: ITranslationEntryUpdate[] = Array.isArray(action.payload.updates) ? action.payload.updates : [action.payload.updates];

            for (const update of updates) {
                state.changes[getChangeId(update.id, update.languageCode)] = update;
            }
        },
        resetTranslationChanges: (state, action: PayloadAction<{ entry: { id: number, languageCode: string } }>) => {
            delete state.changes[getChangeId(action.payload.entry.id, action.payload.entry.languageCode)];
        },
        resetAllChanges: (state) => {
            state.changes = {};
            state.tagChanges = {};
        },
        clearError: (state) => {
            state.errorMessage = undefined;
        },
        revertTagChange: (state, action: PayloadAction<{
            tagState: TagViewState,
            keyId: number,
            languageCode?: string
        }>) => {
            const {tagState, keyId, languageCode} = action.payload;

            const changeId = getTagChangeId({
                tagName: tagState.name,
                keyId,
                languageCode
            });

            if (state.tagChanges.hasOwnProperty(changeId)) {
                delete state.tagChanges[changeId];
            }
        },
        toggleTag: (state, action: PayloadAction<{
            viewState: TagViewState,
            keyId: number,
            languageCode?: string
        }>) => {
            const {viewState, ...payload} = action.payload;

            const identifier = {
                tagName: viewState.name,
                ...payload
            };

            const changeId = getTagChangeId(identifier);

            const status = viewState.status === TranslationTagStatus.Inactive
                ? TranslationTagStatus.Active
                : TranslationTagStatus.Inactive;

            if (status === viewState.originalStatus) {
                if (state.tagChanges.hasOwnProperty(changeId)) {
                    delete state.tagChanges[changeId];
                }
            } else {
                state.tagChanges[changeId] = {
                    identifier,
                    status
                };
            }
        },
        switchTagStatus: (state, action: PayloadAction<{
            keyId: number,
            languageCode?: string,
            status: TranslationTagStatus,
            viewState: TagViewState
        }>) => {
            const {status, viewState} = action.payload;

            const identifier: ITranslationTagStateIdentifier = {
                ...action.payload,
                tagName: viewState.name
            };

            const changeId = getTagChangeId(identifier);

            if (status === viewState.originalStatus) {
                if (state.tagChanges.hasOwnProperty(changeId)) {
                    delete state.tagChanges[changeId];
                }
            } else {
                state.tagChanges[changeId] = {
                    identifier,
                    status: action.payload.status
                };
            }
        },
        updateTags: (state, action: PayloadAction<{
            viewableTags: string[],
            viewStates: TagViewState[],
            keyId: number,
            languageCode?: string
        }>) => {
            const {keyId, languageCode, viewableTags, viewStates} = action.payload;

            for (const tagState of viewStates) {
                const identifier = {
                    tagName: tagState.name,
                    keyId,
                    languageCode
                };

                const switchTo = (status: TranslationTagStatus) => {
                    state.tagChanges[getTagChangeId(identifier)] = {
                        identifier,
                        status: status
                    };
                }

                const revertChange = () => {
                    const changeId = getTagChangeId(identifier);

                    if (state.tagChanges.hasOwnProperty(changeId)) {
                        delete state.tagChanges[changeId];
                    }
                };

                const presentInSelector = viewableTags.includes(tagState.name);

                let caseNo;
                if (presentInSelector) {
                    switch (tagState.originalStatus) {
                        case null:
                        case undefined:
                        case TranslationTagStatus.Deleted:
                            caseNo = 1;
                            (tagState.status == null || tagState.status === TranslationTagStatus.Deleted) && switchTo(TranslationTagStatus.Active);
                            break;
                        case TranslationTagStatus.Active:
                        case TranslationTagStatus.Inactive:
                            caseNo = 2;
                            tagState.status === TranslationTagStatus.Deleted && switchTo(TranslationTagStatus.Active)
                            break;
                        default:
                            throw new Error('Unknown status ' + tagState.originalStatus);
                    }
                } else {
                    switch (tagState.originalStatus) {
                        case null:
                        case undefined:
                        case TranslationTagStatus.Deleted:
                            caseNo = 3;
                            tagState.status != null && revertChange();
                            break;
                        case TranslationTagStatus.Active:
                        case TranslationTagStatus.Inactive:
                            caseNo = 4;
                            switchTo(TranslationTagStatus.Deleted);
                            break;
                        default:
                            throw new Error('Unknown status ' + tagState.originalStatus);
                    }
                }

                console.debug(tagState.name + ': ' + caseNo, {tagState})
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(saveChanges.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = undefined;
            })
            .addCase(saveChanges.fulfilled, (state) => {
                state.isLoading = false;
                state.changes = {};
                state.tagChanges = {};
            })
            .addCase(saveChanges.rejected, (state, action) => {
                state.isLoading = false;
                state.errorMessage = action.error.message;
            })
            .addCase(addKey.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = undefined;
            })
            .addCase(addKey.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(addKey.rejected, (state, action) => {
                state.isLoading = false;
                state.errorMessage = action.error.message;
            })
            .addCase(updateKey.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = undefined;
            })
            .addCase(updateKey.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(updateKey.rejected, (state, action) => {
                state.isLoading = false;
                state.errorMessage = action.error.message;
            });
    }
})

export const {
    changeEntries,
    resetTranslationChanges,
    clearError: clearChangesError,
    updateTags,
    toggleTag,
    revertTagChange,
    resetAllChanges,
    switchTagStatus
} = changesSlice.actions;

export default changesSlice.reducer;
