import {ITranslationEntryTagState} from "../../../shared/models/ITranslationEntryTagState";
import {useTagViewStateProvider} from "./useTagViewStateProvider";
import {useCallback} from "react";
import {groupBy} from "../../../shared/utils/groupBy";
import {TagStatusContainer} from "../types/TagStatusContainer";
import {TagViewState} from "./tagViewState";
import {TranslationTagStatus} from "../../../shared/models/TranslationTagStatus";

type ViewState = { viewState: TagViewState, languageCode?: string }

export const useTagStatusProvider = (keyId: number, tagStates?: ITranslationEntryTagState[]):
    (languageCode: string) => Record<string, TagStatusContainer> => {
    const viewStateProvider = useTagViewStateProvider();

    return useCallback((languageCode: string) => {
        const viewStates: ViewState[] = viewStateProvider(keyId, tagStates, languageCode)
            .map(t => ({
                viewState: t,
                languageCode
            } as ViewState))
            .concat(viewStateProvider(keyId, tagStates)
                .map(t => ({
                    viewState: t,
                    languageCode: undefined
                })));

        const tagGroups = groupBy(viewStates, x => x.viewState.name);

        return Object
            .keys(tagGroups)
            .map(t => {
                const group = tagGroups[t];
                const state = group.filter(x => x.languageCode === languageCode
                        && x.viewState.status != null
                        && x.viewState.status !== TranslationTagStatus.Deleted)[0]
                    || group.filter(x => !x.languageCode
                        && x.viewState.status != null
                        && x.viewState.status !== TranslationTagStatus.Deleted)[0];

                return {
                    tagName: t,
                    status: state?.viewState.status,
                    originalStatus: state?.viewState.originalStatus
                };
            })
            .reduce((result, item) => {
                if (item) {
                    result[item.tagName] = {
                        status: item.status,
                        originalStatus: item.originalStatus
                    };
                }
                return result;
            }, {} as Record<string, TagStatusContainer>);
    }, [keyId, tagStates, viewStateProvider]);
}
