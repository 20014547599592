type ClassName = string | undefined | null

type ConditionalClass = { name?: ClassName, condition?: boolean }

type CssClass = ConditionalClass | ClassName

const EMPTY_CLASS = ''

export const classCombine = (...classes: CssClass[]) => {
    if (!classes?.length) {
        return EMPTY_CLASS;
    }

    const resultClasses: string[] = [];

    for (const cl of classes) {
        if (!cl) {
            continue;
        }

        if (typeof cl === 'string') {
            resultClasses.push(cl);
            continue;
        }

        const conditionalClass = cl as ConditionalClass;

        if (conditionalClass && conditionalClass.name && !!conditionalClass.condition) {
            resultClasses.push(conditionalClass.name);
        }
    }

    return resultClasses.join(' ');
}

