import {ILogRequest} from "./ILogRequest";
import {ILogger} from "./ILogger";
import {LogRequestFactory} from "./LogRequestFactory";

export class LogHandler {
    private static loggers: ILogger[] = [];

    public static get Loggers(): ILogger[] {
        return this.loggers;
    }

    public static debug(category: string, message: string, tracking?: any): void {
        this.log(LogRequestFactory.debug(category, message, tracking));
    }

    public static info(category: string, message: string, tracking?: any): void {
        this.log(LogRequestFactory.info(category, message, tracking));
    }

    public static warn(category: string, message: string, tracking?: any): void {
        this.log(LogRequestFactory.warn(category, message, tracking));
    }

    public static error(category: string, message: string, tracking?: any): void {
        this.log(LogRequestFactory.error(category, message, tracking));
    }

    public static fatal(category: string, message: string, tracking?: any): void {
        this.log(LogRequestFactory.fatal(category, message, tracking));
    }

    private static log(request: ILogRequest) {
        try {
            this.loggers.forEach(logger => logger.log(request));
        } catch (e) {
            console.error("can't log", e)
        }
    }
}
