import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../store/types";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {normalizeObject} from "../../../shared/utils/normalizeObject";
import {Query} from "../../../shared/utils/query";
import {RecordUtils} from "../../../shared/utils/recordUtils";
import {LocalStorage} from "../../../shared/utils/localStorage";
import {ViewMode} from "../../../shared/models/viewMode";
import {UserRole} from "../../../shared/models/userRole";
import {ITranslationTagMetadata} from "../../../shared/models/ITranslationTagMetadata";
import {SearchMode} from "../../../shared/models/searchMode";

type Settings = {
    system: {
        languages: Record<string, string>
    },
    user: {
        projects: Record<string, string>
        languages: Record<string, string>
        sourceLanguage: string,
        viewModes: ViewMode[],
        allLanguagesAvailable: boolean
    },
    view: {
        project: string,
        language: string,
        searchOptions: {
            searchMode: SearchMode
        },
        searchQuery?: string,
        viewMode: ViewMode
    },
    project: {
        languages: Record<string, string>
    },
    tagsMetadata: ITranslationTagMetadata[]
}

export const getSettings = createAsyncThunk('settings/getSettings', async (_, {getState}): Promise<Settings> => {
    const state = getState() as RootState;
    const apiClient = new LocalizationClient(state.user.jwt);
    const userRole = state.user.user!.role;

    const systemLanguagesResponse = await apiClient.getSystemLanguages();
    const systemLanguages = normalizeObject(systemLanguagesResponse.data);

    const projectsResponse = await apiClient.getProjects();
    const projects = normalizeObject(projectsResponse.data.projects);

    const tagsMetadata = await apiClient.listTags();

    const persistedProject = (Query.getProject() || LocalStorage.getSelectedProject())?.toLowerCase();
    const persistedLanguage = (Query.getLanguage() || LocalStorage.getSelectedLanguage())?.toLowerCase();

    const selectedProject = RecordUtils.containsKey(projects, persistedProject)
        ? persistedProject
        : RecordUtils.firstOrDefaultKey(projects);

    const languagesResponse = await apiClient.getLanguages(selectedProject);
    const [userLanguages, projectLanguages, sourceLanguage] = [
        normalizeObject(languagesResponse.data.userLanguages),
        normalizeObject(languagesResponse.data.projectLanguages),
        languagesResponse.data.sourceLanguage.toLowerCase()
    ];

    const selectedLanguage = RecordUtils.containsKey(userLanguages, persistedLanguage) && persistedLanguage !== sourceLanguage
        ? persistedLanguage
        : RecordUtils.firstOrDefaultKey(userLanguages, value => value !== sourceLanguage);

    Query.setProject(selectedProject);
    Query.setLanguage(selectedLanguage);

    const userLanguageCodes = Object.keys(userLanguages);
    const allLanguagesAvailable = Object.keys(projectLanguages).every(v => userLanguageCodes.includes(v));

    const viewModes: ViewMode[] = Object
        .entries(ViewMode)
        .filter(x => !isNaN(x[0] as any))
        .map(x => Number(x[0]) as ViewMode)
        .filter(vm => {

            if (userRole < UserRole.Admin) {
                return vm !== ViewMode.Populate && vm !== ViewMode.ToCheck && vm !== ViewMode.UpperCase;
            }

            if (vm === ViewMode.Populate) {
                return allLanguagesAvailable;
            }

            return true;
        });

    const persistedViewMode = Query.getViewMode();
    const defaultViewMode = state.user.user!.role === UserRole.Translator
        ? ViewMode.ToTranslate
        : ViewMode.All;
    const selectedViewMode = persistedViewMode && viewModes.includes(persistedViewMode)
        ? persistedViewMode
        : defaultViewMode;

    return {
        system: {
            languages: systemLanguages
        },
        user: {
            projects,
            languages: userLanguages,
            sourceLanguage,
            viewModes,
            allLanguagesAvailable
        },
        view: {
            project: selectedProject.toLowerCase(),
            language: selectedLanguage.toLowerCase(),
            searchOptions: {
                searchMode: Query.getSearchMode()
            },
            searchQuery: Query.getSearchQuery(),
            viewMode: selectedViewMode
        },
        project: {
            languages: projectLanguages
        },
        tagsMetadata
    };
});
