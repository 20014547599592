import {IUserState} from "../states/IUserState";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {authenticate} from "../thunks/user/authenticate";
import {authorize} from "../thunks/user/authorize";

const initialState: IUserState = {
    jwt: undefined,
    authenticated: false,
    authorized: false,
    isLoading: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        signOut: (state) => {
            state.isLoading = false;
            state.authenticated = false;
            state.authorized = false;
            state.jwt = undefined;
            state.errorMessage = undefined;
            state.user = undefined;
        },
        setJwt: (state, action: PayloadAction<string>) => {
            state.jwt = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(authenticate.pending, (state) => {
                state.authenticated = false;
                state.authorized = false;
                state.jwt = undefined;
                state.errorMessage = undefined;

                state.isLoading = true;
            })
            .addCase(authenticate.fulfilled, (state, action) => {
                state.isLoading = false;

                state.authenticated = true;
                state.jwt = action.payload;
            })
            .addCase(authenticate.rejected, (state, action) => {
                state.isLoading = false;
                state.authenticated = false;
                state.authorized = false;

                state.errorMessage = action.error.message;
            })
            .addCase(authorize.pending, (state) => {
                state.authorized = false;

                state.isLoading = true;
            })
            .addCase(authorize.fulfilled, (state, action) => {
                state.isLoading = false;

                state.user = action.payload.user;
                state.jwt = action.payload.jwtToken;
                state.authenticated = true;
                state.authorized = true;
            })
            .addCase(authorize.rejected, (state, action) => {
                state.isLoading = false;
                state.authenticated = false;
                state.authorized = false;

                state.errorMessage = action.error.message;
            })
    }
});

export default userSlice.reducer;
