import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../store/types";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {getTranslations} from "../translations/getTranslations";
import {IKeyUpdateRequest} from "../../../shared/models/IKeyUpdateRequest";

type UpdateKeyThunkProps = {
    request: IKeyUpdateRequest
}

export const updateKey = createAsyncThunk('changes/updateKey', async ({request}: UpdateKeyThunkProps, {getState, dispatch}) => {
    const state = getState() as RootState;

    const client = new LocalizationClient(state.user.jwt);
    await client.updateKey(request);

    // TODO: Highlight new key
    dispatch(getTranslations());
});
