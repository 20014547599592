import {configureStore} from "@reduxjs/toolkit";
import settingsReducer from "../reducers/settingsReducer";
import userReducer from "../reducers/userReducer";
import viewReducer from "../reducers/viewReducer";
import translationsReducer from "../reducers/translationsReducer";
import changesReducer from "../reducers/changesReducer";

export const store = configureStore({
    reducer: {
        view: viewReducer,
        settings: settingsReducer,
        user: userReducer,
        translations: translationsReducer,
        changes: changesReducer
    },
    devTools: true
});
