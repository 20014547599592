import React, {useEffect, useMemo, useState} from "react"
import {Alert, Autocomplete, Box, Button, Checkbox, CircularProgress, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, TextField} from "@mui/material";
import {DialogContainer} from "../../dialogs/components/DialogContainer";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {useApiClient} from "../../../shared/api/hooks/useApiClient";
import {DialogCloseButton} from "../../dialogs/components/DialogCloseButton";
import {changeEntries} from "../../../app/reducers/changesReducer";
import {useCopyTranslationsDialog} from "../hooks/useCopyTranslationsDialog";
import {useAppDispatch} from "../../../app/hooks/useAppDispatch";
import {useKeyOptions} from "../../translations/hooks/useKeyOptions";

export const CopyTranslationsDialog = () => {
    const dispatch = useAppDispatch();
    const context = useCopyTranslationsDialog();
    const apiClient = useApiClient();

    const projects = useAppSelector(s => s.settings.user.projects)
    const selectedProject = useAppSelector(s => s.view.project)
    const selectedLanguageCode = useAppSelector(s => s.view.language)

    const [targetProject, setTargetProject] = useState<string>()
    const [targetId, setTargetId] = useState<number>();
    const [selectedLanguageOnly, setSelectedLanguageOnly] = useState<boolean>(true);
    const [request, setRequest] = useState<{ sourceId: number, targetId: number, languageCode: string | undefined, overwrite: boolean }>();
    const [error, setError] = useState<string>();
    const [info, setInfo] = useState<string>();
    const [loading, setLoading] = useState(false);

    const shown = context.hasShown();
    const {sourceId} = context.getData();

    const keyOptions = useKeyOptions(targetProject, shown);

    const submit = (overwrite: boolean) => {
        targetId
        && sourceId
        && setRequest({sourceId, targetId, languageCode: selectedLanguageOnly ? selectedLanguageCode : undefined, overwrite})
    };

    useEffect(() => context.onHideSubscription(() => {
        setInfo(undefined);
        setError(undefined);
        setRequest(undefined);
        setTargetId(undefined)
    }), [context]);

    useEffect(() => {
        setTargetProject(selectedProject)
    }, [selectedProject]);

    useEffect(() => {
        if (!request || loading) {
            return;
        }

        setLoading(true);
        setRequest(undefined);
        setError(undefined);
        setInfo(undefined);

        apiClient.current.copyTranslations(
            request.sourceId,
            request.targetId,
            request.languageCode,
            request.overwrite
        )
            .then(values => {
                if (values?.length) {
                    dispatch(changeEntries({updates: values}));
                    setInfo('Changes have been made');
                } else {
                    setInfo('Nothing to copy');
                }
            })
            .catch(e => setError(e))
            .finally(() => {
                setLoading(false);
                setTargetId(undefined);
            })
    }, [apiClient, dispatch, loading, request]);

    const renderAlerts = () => (
        <React.Fragment>
            {error && <Grid item><Alert severity="error">{error}</Alert></Grid>}
            {info && <Grid item><Alert severity="info">{info}</Alert></Grid>}
        </React.Fragment>
    )

    const renderLoading = () => (
        <Box sx={{display: 'flex'}}>
            <CircularProgress/>
        </Box>
    )

    const renderProjectSelector = () => (
        <Grid item>
            <FormControl fullWidth>
                <TextField label="Target Project"
                           value={targetProject}
                           select
                           onChange={x => setTargetProject(x.target.value)}>
                    {Object.keys(projects)
                        .sort((a, b) => projects[a].localeCompare(projects[b]))
                        .map(p => <MenuItem value={p} key={p}>{projects[p]}</MenuItem>)}
                </TextField>
            </FormControl>
        </Grid>
    )

    const filteredKeyOptions = useMemo(() => keyOptions.filter(x => x.value !== sourceId), [keyOptions, sourceId]);

    const renderTargetKeySelector = () => (
        <Grid item>
            <FormControl fullWidth>
                <Autocomplete renderInput={(params) => <TextField {...params} label='Target Key'/>}
                              groupBy={option => option.group}
                              onChange={(_, v) => setTargetId(v?.value || undefined)}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              options={filteredKeyOptions}/>
            </FormControl>
        </Grid>
    )

    const renderOptionFlags = () => (
        <Grid item>
            <FormGroup>
                <FormControlLabel
                    label="Current Language Only"
                    control={<Checkbox checked={selectedLanguageOnly}
                                       onChange={e => setSelectedLanguageOnly(e.target.checked)}/>}
                />
            </FormGroup>
        </Grid>
    )

    return (
        <DialogContainer dialogName={context.dialogName}>
            <DialogTitle>#{sourceId} Copy Translations</DialogTitle>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent dividers>
                <Grid container direction={"column"} spacing={2}>
                    {renderAlerts()}

                    {loading ?
                        renderLoading()
                        : <React.Fragment>
                            {renderProjectSelector()}

                            {renderTargetKeySelector()}

                            {renderOptionFlags()}
                        </React.Fragment>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => submit(false)} disabled={loading || !targetId}>Populate</Button>
                <Button onClick={() => submit(true)} disabled={loading || !targetId}>Overwrite</Button>
            </DialogActions>
        </DialogContainer>
    )
}
