import React from "react"
import style from "./KeyDescription.module.scss";

export type KeyDescriptionProps = {
    content: string
}

export function KeyDescription({content}: KeyDescriptionProps) {
    return (
        <React.Fragment>
            {content && <div className={style.container} dangerouslySetInnerHTML={{__html: content}}></div>}
        </React.Fragment>
    )
}
