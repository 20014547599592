import {AxiosError, AxiosResponse} from "axios";
import {IErrorResponse} from "../models/IErrorResponse";

export class AxiosUtils {
    public static createErrorResponse(axiosError: AxiosError<any, any>): IErrorResponse {
        const errorResponse: IErrorResponse = {message: ""};

        const axiosResponse: AxiosResponse<IErrorResponse> | undefined = axiosError.response;

        if (!axiosResponse) {
            errorResponse.message = `${axiosError}`;
        } else if (!axiosResponse.data?.message) {
            errorResponse.message = `${axiosResponse!.status} ${axiosResponse!.statusText}`;
        } else {
            errorResponse.message = axiosResponse.data.message;
        }

        if (!errorResponse.message) {
            errorResponse.message = "Unknown error";
        }

        return errorResponse;
    }
}
