import {Alert, AlertTitle, DialogContent} from "@mui/material";
import {DialogCloseButton} from "../../dialogs/components/DialogCloseButton";
import * as React from "react";
import {DialogContainer} from "../../dialogs/components/DialogContainer";
import {useErrorAlertDialog} from "../hooks/useErrorAlertDialog";

export const ErrorAlertDialog = () => {
    const context = useErrorAlertDialog();

    return (
        <DialogContainer dialogName={context.dialogName}>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent sx={{padding: '0'}}>
                <Alert severity="error">
                    <AlertTitle>{context.getData().message}</AlertTitle>
                    <p>An unexpected error has occurred.</p>
                    <p><strong>Please try again in a few moments.</strong></p>
                </Alert>
            </DialogContent>
        </DialogContainer>
    )
}
