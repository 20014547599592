import style from "./entry.module.scss";
import {TextEditor} from "../text-editor/TextEditor";
import {IconButton} from "@mui/material";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import * as React from "react";
import {useTranslationChange} from "../../hooks/useTranslationChange";
import {ITranslationEntry} from "../../../../shared/models/ITranslationEntry";
import {useAppSelector} from "../../../../app/hooks/useAppSelector";
import {useUserRole} from "../../../../app/hooks/useUserRole";
import {ITranslationEntryValue} from "../../../../shared/models/ITranslationEntryValue";
import {classCombine} from "../../../../shared/utils/classCombine";
import {Children} from "../../../../shared/types";
import {useFullScreenEditorDialog} from "../../hooks/useFullScreenEditorDialog";
import {UserRole} from "../../../../shared/models/userRole";
import {useTagStatuses} from "../../../tags/hooks/useTagStatuses";
import {TranslationTagStatus} from "../../../../shared/models/TranslationTagStatus";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {useTranslationCheckActions} from "../../hooks/useTranslationCheckActions";
import {TagNames} from "../../../tags/TagNames";

type EntryTranslationProps = {
    entry: ITranslationEntry
    languageCode: string
    className?: string
    tools?: Children
}

export const EntryTranslation = ({entry, languageCode, className, tools}: EntryTranslationProps) => {
    const {
        currentValue: value,
        onChange,
        commit: commitChange,
        changed
    } = useTranslationChange(entry, languageCode);

    const tagStatuses = useTagStatuses(entry.id, languageCode, entry.tagStates);
    const [acceptChange, rejectChange] = useTranslationCheckActions(entry.id, languageCode, entry.tagStates);

    const [userRole] = useUserRole();

    const sourceLanguage = useAppSelector(state => state.settings.user.sourceLanguage);
    const languages = useAppSelector(state => state.settings.user.languages);

    const isReadOnly = (languageCode === sourceLanguage && userRole !== UserRole.Admin) || userRole === UserRole.Viewer;
    const lengthExceeding = !!entry.translationLengthLimit && value?.length > entry.translationLengthLimit;

    const highlightColor = lengthExceeding
        ? 'red'
        : changed
            ? 'yellow'
            : tagStatuses[TagNames.UpdateRequired]?.status === TranslationTagStatus.Active
                ? '#b5dc82'
                : undefined;

    const fullscreenDialog = useFullScreenEditorDialog();

    const renderDates = (value: ITranslationEntryValue) => (
        <React.Fragment>
            {value && (
                value.updatedOn || value.updatedBy ?
                    <span>
                        {value.updatedOn && <span>Last update {value.updatedOn}</span>}
                        {value.updatedBy && <span> by <i>{value.updatedBy}</i></span>}
                    </span> :
                    <span>
                        {value.createdOn && <span>Created {value.createdOn}</span>}
                        {value.createdBy && <span> by <i>{value.createdBy}</i></span>}
                    </span>
            )}
        </React.Fragment>
    )

    return (
        <span key={`${entry.id}_${languageCode}`} className={`${className} ${style.editors}`}>
            <div className={style.languageName}>
                {languages[languageCode]}

                {tools &&
                    <div className={style.tools}>
                        {tools}
                    </div>
                }
            </div>

            <TextEditor value={value}
                        onChange={onChange}
                        onBlur={commitChange}
                        readOnly={isReadOnly}
                        outlineColor={highlightColor}
            />

            <div className={style.footerToolbox}>
                    <div className={style.leftFooterToolbox}>
                        <span className={classCombine({
                                name: style.warnLength,
                                condition: lengthExceeding
                            }
                        )}>Length: {value ? value.length : 0}</span>
                    </div>

                    <div className={style.rightFooterToolbox}>
                        {userRole === UserRole.Admin && renderDates(entry.values[languageCode])}
                        {userRole === UserRole.Admin && tagStatuses[TagNames.ToCheck]?.status === TranslationTagStatus.Active &&
                            <React.Fragment>
                                <IconButton
                                    sx={{
                                        padding: '2px 2px',
                                        color: '#79AC78'
                                    }}
                                    title='Accept change'
                                    onClick={() => acceptChange()}>
                                    <ThumbUpIcon fontSize={'small'}/>
                                </IconButton>

                                <IconButton
                                    sx={{
                                        padding: '2px 2px',
                                        color: '#FF8080'
                                    }}
                                    title='Reject change'
                                    onClick={() => rejectChange()}>
                                    <ThumbDownIcon fontSize={'small'}/>
                                </IconButton>
                            </React.Fragment>}

                        {value && value.length > 500 &&
                            <IconButton
                                sx={{
                                    padding: '2px 2px'
                                }}
                                onClick={() => fullscreenDialog.show({entry, languageCode})}
                                title='Click to enter fullscreen mode'><FullscreenOutlinedIcon fontSize={'small'}/></IconButton>
                        }
                    </div>
            </div>
        </span>
    )
}
