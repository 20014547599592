import {useFullScreenEditorDialog} from "../../hooks/useFullScreenEditorDialog";
import {DialogContent, DialogTitle, Typography, useMediaQuery, useTheme} from "@mui/material";
import {DialogCloseButton} from "../../../dialogs/components/DialogCloseButton";
import * as React from "react";
import {DialogContainer} from "../../../dialogs/components/DialogContainer";
import {useTranslationChange} from "../../hooks/useTranslationChange";
import {useAppSelector} from "../../../../app/hooks/useAppSelector";
import {TextEditor} from "../text-editor/TextEditor";
import {useUserRole} from "../../../../app/hooks/useUserRole";
import {ITranslationEntry} from "../../../../shared/models/ITranslationEntry";
import {LocalizationKey} from "./LocalizationKey";
import {UserRole} from "../../../../shared/models/userRole";

const Editor = ({entry, languageCode}: { entry: ITranslationEntry; languageCode: string }) => {
    const {
        currentValue: value,
        onChange,
        commit: commitChange
    } = useTranslationChange(entry, languageCode);

    const [userRole] = useUserRole();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const sourceLanguage = useAppSelector(state => state.settings.user.sourceLanguage);

    const isReadOnly = (languageCode === sourceLanguage && userRole !== UserRole.Admin) || userRole === UserRole.Viewer;

    return (
        <TextEditor value={value}
                    onChange={onChange}
                    onBlur={commitChange}
                    readOnly={isReadOnly}
                    height={isMobile ? 'calc(100vh - 130px)' : '50vh'}
                    minHeight={'unset'}
                    maxHeight={'unset'}
        />
    )
}

export const FullScreenEditorDialog = () => {
    const context = useFullScreenEditorDialog();
    const data = context.getData();
    const languages = useAppSelector(state => state.settings.user.languages);

    return (
        <DialogContainer dialogName={context.dialogName}>
            <DialogTitle><LocalizationKey text={data?.entry?.key}/></DialogTitle>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent dividers>
                {data.entry && data.languageCode &&
                    <React.Fragment>
                        <Typography>
                            <strong>{languages[data.languageCode] || data.languageCode}</strong>
                        </Typography>
                        <Editor entry={data.entry} languageCode={data.languageCode}/>
                    </React.Fragment>}
            </DialogContent>
        </DialogContainer>
    )
}
