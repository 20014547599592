import React from "react";
import style from "./entrylist.module.css";
import {Entry} from "../entry/Entry";
import {useAppSelector} from "../../../../app/hooks/useAppSelector";

export const EntryList = () => {
    const shownEntries = useAppSelector(state => state.view.viewableEntryIds);
    const entries = useAppSelector(state => state.translations.entries);
    // TODO: HashLink
    // const hashLink = useAppSelector(state => state.view.hashLink);

    return (
        <section className={style.entryList}>
            {entries.filter(entry => shownEntries
                .includes(entry.id))
                .map(entry => <Entry
                    key={entry.id}
                    entry={entry}
                    // TODO: hashLink={hashLink}
                />)}
        </section>
    )
}
