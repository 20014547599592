import * as React from "react";
import {useEffect, useState} from "react";
import style from "./header.module.scss"
import {Media} from "../../shared/utils/media";
import {ViewMode} from "../../shared/models/viewMode";
import {useAppSelector} from "../hooks/useAppSelector";
import {useHasUnsavedChanges} from "../hooks/useHasUnsavedChanges";
import {signOut} from "../thunks/user/signOut";
import {Query} from "../../shared/utils/query";
import {changeLanguage, changeSearchOptions, changeViewMode, search} from "../reducers/viewReducer";
import {LocalStorage} from "../../shared/utils/localStorage";
import {saveChanges} from "../thunks/changes/saveChanges";
import {useUserRole} from "../hooks/useUserRole";
import {useChangesDialog} from "../../features/changes-dialog/hooks/useChangesDialog";
import {useEditKeyDialog} from "../../features/edit-key/hooks/useEditKeyDialog";
import {useAppDispatch} from "../hooks/useAppDispatch";
import {resetAllChanges} from "../reducers/changesReducer";
import {APP_TITLE} from "../../shared/constants";
import {Environment} from "../../shared/utils/environment";
import {UserRole} from "../../shared/models/userRole";
import {changeProject} from "../thunks/view/changeProject";
import {SearchMode} from "../../shared/models/searchMode";
import {Autocomplete, FormControl, MenuItem, Select, Stack, TextField} from "@mui/material";
import {KeyOptionValue, useKeyOptions} from "../../features/translations/hooks/useKeyOptions";
import MenuIcon from '@mui/icons-material/Menu';
import {normalizeCase, useNormalizeCaseAvailable} from "../thunks/tools/normalizeCase";
import {useAppVersions} from "../../features/translations/hooks/useAppVersions";

const viewModeDisplayNames: Record<number, string> = {
    [ViewMode.All]: "All",
    [ViewMode.ToTranslate]: "To Translate",
    [ViewMode.Empty]: "Empty",
    [ViewMode.ToCheck]: "To Check",
    [ViewMode.Recent]: "Recent"
};

export const Header = () => {
    const dispatch = useAppDispatch();
    const [userRole] = useUserRole();
    const normalizeCaseAvailable = useNormalizeCaseAvailable();

    const sourceLanguage = useAppSelector(state => state.settings.user.sourceLanguage);
    const targetLanguage = useAppSelector(state => state.view.language);
    const project = useAppSelector(state => state.view.project);
    const viewMode = useAppSelector(state => state.view.viewMode);
    const searchQuery = useAppSelector(state => state.view.searchQuery);
    const searchOptions = useAppSelector(state => state.view.searchOptions);
    const projects = useAppSelector(state => state.settings.user.projects);
    const languages = useAppSelector(state => state.settings.user.languages);
    const languagesLoading = useAppSelector(state => state.settings.languagesLoading);
    const hasChanges = useHasUnsavedChanges();
    const keyOptions = useKeyOptions(project, true);
    const originalAppVersions = useAppVersions(project);

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [menuShown, setMenuShown] = useState<boolean>(false);

    const editKeyDialogContext = useEditKeyDialog();
    const changesDialogContext = useChangesDialog();

    const updateMedia = () => {
        setIsMobile(Media.isMobile());
    }

    useEffect(() => {
        updateMedia();

        const onResize = () => {
            updateMedia();
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    const onMenuToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMenuShown(e.target.checked);
    }

    const renderMenuButton = () => (
        <div className={style.menuButtonContainer}>
            <label htmlFor={"menu-toggle"}
                   className={style.menuButton}>
                <MenuIcon/>
            </label>
            <input className={style.menuToggle}
                   type="checkbox"
                   id={"menu-toggle"}
                   onChange={onMenuToggleChange}
                   defaultChecked={false}/>
        </div>
    )

    const getMenuVisibility = () => {
        if (isMobile) {
            return menuShown;
        }

        return true;
    }

    const renderCenterBlock = () => (
        <div className={style.centerBlock}>
            {renderSearchBar()}
        </div>
    )

    const renderLeftBlock = () => (
        <div className={style.leftBlock}>
            <div className={style.title}>
                <span>
                    {APP_TITLE}
                </span>

                <span>
                    {Environment.isAlpha()
                        ? 'α'
                        : Environment.isBeta()
                            ? 'β'
                            : Environment.isDevelopment()
                                ? 'Dev'
                                : ''}
                </span>
            </div>
        </div>
    )

    const renderRightBlock = () => (
        <React.Fragment>
            {getMenuVisibility() &&
                <div className={style.rightBlock}>
                    {renderLanguagesDropdown()}
                    {renderViewModesDropdown()}
                    {renderProjectDropdown()}
                </div>}
        </React.Fragment>
    )

    const renderSubheader = () => (
        <React.Fragment>
            {getMenuVisibility() &&
                <div className={style.subHeader}>
                    {userRole !== UserRole.Viewer &&
                        <React.Fragment>
                            <button type="button"
                                    disabled={!hasChanges}
                                    className={style.linkButton}
                                    onClick={() => changesDialogContext.show()}>Show changes
                            </button>
                            <button type="button"
                                    disabled={!hasChanges}
                                    className={style.linkButton}
                                    onClick={onSaveChangesClick}>Save changes
                            </button>
                            <button type="button"
                                    disabled={!hasChanges}
                                    className={style.linkButton}
                                    onClick={onResetChangesClick}>Reset changes
                            </button>
                            {userRole === UserRole.Admin &&
                                <button className={style.linkButton}
                                        onClick={addKeyButtonClickEventHandler}>Add key</button>}

                            {userRole === UserRole.Admin && normalizeCaseAvailable &&
                                <button className={style.linkButton}
                                        onClick={() => {
                                            dispatch(normalizeCase());
                                        }}>Normalize Case</button>}
                        </React.Fragment>}
                    <button type="button"
                            className={style.linkButton}
                            onClick={onLogoutClick}>Logout
                    </button>
                </div>}
        </React.Fragment>
    )

    const viewModes = useAppSelector(x => x.settings.user.viewModes);

    const renderViewModesDropdown = () => {
        return (
            <div className={style.viewModes}>
                <select className={style.dropdown}
                        value={viewMode}
                        onChange={onViewModeChange}
                        title={"View mode"}>

                    {viewModes.map(vm => {
                            const displayName: string = viewModeDisplayNames[vm] || ViewMode[vm];

                            return (
                                <option key={vm} value={vm}>{displayName}</option>
                            )
                        }
                    )}
                </select>
            </div>
        )
    }

    const renderSearchModeDropdown = () => {
        const values = Object
            .keys(SearchMode)
            .filter(k => typeof SearchMode[k as any] === "number")
            .map(k => SearchMode[k as any]);

        return (
            <FormControl>
                <Select
                    value={searchOptions.searchMode}
                    size={'small'}
                    variant={"standard"}
                    sx={{
                        fontSize: '10px',
                        color: 'black',
                        outline: 'none',
                        border: 'none',
                        paddingLeft: '4px',
                        '& .MuiInput-input': {
                            padding: '0',
                            outline: 'none',
                            border: 'none',

                        },
                        "& .MuiInput-input:focus": {
                            backgroundColor: 'white',
                            outline: 'none',
                            border: 'none',
                        }
                    }}
                    disableUnderline
                    onChange={event => {
                        const searchMode = Number(event.target.value);
                        Query.setSearchMode(searchMode);
                        dispatch(changeSearchOptions({
                            ...searchOptions,
                            searchMode: searchMode
                        }));
                    }}
                >
                    {values.map(mode => {
                            const sm = Number(mode);
                            const displayName: string = SearchMode[sm];

                            return (
                                <MenuItem key={mode} value={mode}>{displayName}</MenuItem>
                            )
                        }
                    )}
                </Select>
            </FormControl>
        )
    }

    const renderProjectDropdown = () => {
        return (
            <div className={style.projects}>
                <select className={style.dropdown}
                        value={project}
                        onChange={onProjectChange}
                        disabled={languagesLoading}>

                    {Object.keys(projects).sort()
                        .map(key =>
                            <option key={key} value={key}>{projects[key]}</option>
                        )}

                </select>
            </div>
        )
    }

    const renderLanguagesDropdown = () => (
        <div className={style.languages}>
            <select className={style.dropdown}
                    value={targetLanguage}
                    onChange={onLanguageChange}
                    disabled={languagesLoading}>
                {!languagesLoading && Object.keys(languages)
                    .filter(l => l !== sourceLanguage)
                    .sort((a, b) => languages[a].localeCompare(languages[b]))
                    .map(key =>
                        <option key={key} value={key}>{languages[key]}</option>
                    )}
            </select>
        </div>
    )

    const renderSearchBar = () => {
        return (
            <div className={style.searchBar}>
                <Autocomplete renderInput={(params) => <TextField {...params}
                                                                  type="text"
                                                                  size='small'
                                                                  placeholder="Search"
                                                                  variant={"standard"}
                                                                  className={style.searchInput}
                                                                  value={searchQuery}
                                                                  inputProps={{
                                                                      ...params.inputProps,
                                                                      enterKeyHint: 'done'
                                                                  }}
                                                                  InputProps={{
                                                                      ...params.InputProps,
                                                                      disableUnderline: true,
                                                                      style: {
                                                                          fontSize: '12px',
                                                                          paddingLeft: '5px'
                                                                      }
                                                                  }}/>}
                              sx={{
                                  '& .MuiFormControl-root': {
                                      padding: '2px'
                                  }
                              }}
                              onChange={(_, value) => {
                                  const text = (value as KeyOptionValue)?.label || value as string;
                                  Query.setSearchQuery(text);
                                  dispatch(search(text));
                              }}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              fullWidth
                              groupBy={option => option.group}
                              ListboxProps={{
                                  style: {
                                      fontSize: '12px'
                                  }
                              }}
                              componentsProps={{
                                  paper: {
                                      sx: {
                                          width: 'max(min(60vw, 700px), 100%)',
                                          marginTop: '5px'
                                      }
                                  }
                              }}
                              value={searchQuery}
                              clearOnEscape
                              options={searchOptions.searchMode === SearchMode.Translations
                                  ? []
                                  : searchOptions.searchMode === SearchMode.AppVersions
                                      ? originalAppVersions
                                      : keyOptions}
                              freeSolo
                              size={'small'}/>
                <Stack direction={'row'} alignItems={'center'}>
                    {renderSearchModeDropdown()}
                </Stack>
            </div>
        )
    }

    const addKeyButtonClickEventHandler = () => {
        editKeyDialogContext.show({});
    }

    const onResetChangesClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const confirmationMessage = "Are you sure want to discard your changes?";

        if (window.confirm(confirmationMessage)) {
            dispatch(resetAllChanges());
        }
    }

    const onLogoutClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        dispatch(signOut());
    }

    const onViewModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const viewMode = Number(event.target.value);
        Query.setViewMode(viewMode);
        dispatch(changeViewMode(viewMode));
    }

    const onLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const languageCode = event.target.value;
        Query.setLanguage(languageCode);
        LocalStorage.saveSelectedLanguage(languageCode);
        dispatch(changeLanguage(event.target.value));
    }

    const onProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(changeProject({selectedProject: event.target.value}));
    }

    const onSaveChangesClick = () => {
        dispatch(saveChanges());
    }

    return (
        <header className={style.header}>
            {renderMenuButton()}

            <div className={style.mainHeader}>
                {renderLeftBlock()}
                {renderCenterBlock()}
                {renderRightBlock()}
            </div>

            {renderSubheader()}
        </header>
    )
}
