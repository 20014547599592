import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import * as React from "react";
import {DialogName} from "../types/dialogName";
import {useDialogContext} from "../hooks/useDialogContext";

type DialogCloseButtonProps = {
    dialogName: DialogName
}

export const DialogCloseButton = ({dialogName}: DialogCloseButtonProps) => {
    const dialogContext = useDialogContext<any>(dialogName);

    return (
        <IconButton
            aria-label="close"
            onClick={() => dialogContext.hide()}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon/>
        </IconButton>
    )
}
