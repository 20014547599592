import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import {ApiGetEntriesUrlBuilder} from "./ApiGetEntriesUrlBuilder";
import {ApiUrlBuilder} from "./ApiUrlBuilder";
import {ApiUrlProvider} from "./ApiUrlProvider";
import {IUserCredentials} from "../../models/IUserCredentials";
import {ITranslationEntryUpdate} from "../../models/ITranslationEntryUpdate";
import {ITranslationEntriesRequest} from "../../models/ITranslationEntriesRequest";
import {IKeyCreationRequest} from "../../models/IKeyCreationRequest";
import {IKeyUpdateRequest} from "../../models/IKeyUpdateRequest";
import {ITranslationEntry} from "../../models/ITranslationEntry";
import {IApiResponse} from "../../models/IApiResponse";
import {ILogRequest} from "../../../features/logging/ILogRequest";
import {ILocalizationTranslation} from "../../models/ILocalizationTranslation";
import {IUser} from "../../models/IUser";
import {ITranslationEntriesResponse} from "../../models/ITranslationEntriesResponse";
import {ITranslationTagMetadata} from "../../models/ITranslationTagMetadata";
import {ITranslationTagStatusChangeRequest} from "../../models/ITranslationTagStatusChangeRequest";
import {Api} from "@mui/icons-material";

export class LocalizationClient {
    private readonly apiUrl: string;
    private readonly jwtToken: string | undefined;
    private readonly httpClient: AxiosInstance;

    public constructor(jwtToken?: string, apiUrl?: string) {
        this.apiUrl = apiUrl || ApiUrlProvider.get();
        this.jwtToken = jwtToken;
        this.httpClient = axios.create(this.axiosConfig);
    }

    private get axiosConfig(): AxiosRequestConfig {
        let config: AxiosRequestConfig = {
            baseURL: this.apiUrl
        };

        if (this.jwtToken) {
            return {
                ...config,
                headers: {
                    Authorization: "Bearer " + this.jwtToken
                },
                responseType: "json"
            }
        }

        return config;
    }

    public async uploadImages(prefix: string, files: File[]): Promise<string[]> {
        const urlBuilder = new ApiUrlBuilder('/api/images/upload');
        urlBuilder.setParameter('prefix', prefix);

        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });

        const response = await this.httpClient.post<string[]>(urlBuilder.build(), formData, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        });

        return response.data;
    }

    public async listTags() {
        const urlBuilder = new ApiUrlBuilder('/api/tags/list');

        const response = await this.httpClient.get<ITranslationTagMetadata[]>(urlBuilder.build());

        return response.data;
    }

    public async switchTagStatus(requests: ITranslationTagStatusChangeRequest[]) {
        const urlBuilder = new ApiUrlBuilder('/api/tags/switchstatus');

        await this.httpClient.post(urlBuilder.build(), requests);
    }

    public async log(request: ILogRequest) {
        const apiRequest = {
            ...request,
            apiKey: 'vfzy04QcLUedHo7UMtoOfA'
        };

        let urlBuilder: ApiUrlBuilder;

        if (this.jwtToken) {
            urlBuilder = new ApiUrlBuilder(`/api/logs/log`);
        } else {
            urlBuilder = new ApiUrlBuilder(`/api/logs/logAnonymous`);
        }

        const url = urlBuilder.build();

        return this.httpClient.post(url, apiRequest);
    }

    public async getEntries(request: ITranslationEntriesRequest) {
        let urlBuilder = new ApiGetEntriesUrlBuilder(`/api/localization`);

        urlBuilder
            .setProject(request.project)
            .setSearchQuery(request.searchQuery)
            .setOffset(request.offset)
            .setLimit(request.limit)
            .setSortDirection(request.sortDirection)
            .setViewMode(request.viewMode)
            .setTargetLanguage(request.targetLanguage)
            .setSourceLanguage(request.sourceLanguage)
            .setSearchMode(request.searchMode);

        const url = urlBuilder.build();

        return this.httpClient.get<ITranslationEntriesResponse>(url);
    }

    public getExportUrl(request: ITranslationEntriesRequest, languages: string[]) {
        let urlBuilder = new ApiGetEntriesUrlBuilder(`/api/export/get`);

        urlBuilder
            .setProject(request.project)
            .setSearchQuery(request.searchQuery)
            .setViewMode(request.viewMode)
            .setTargetLanguage(request.targetLanguage)
            .setSourceLanguage(request.sourceLanguage)
            .setSearchMode(request.searchMode)
            .setLanguages(languages);

        return this.apiUrl + urlBuilder.build();
    }

    public async saveTranslationChanges(changedEntries: ITranslationEntryUpdate[]) {
        let urlBuilder = new ApiUrlBuilder(`/api/localization`);

        const url = urlBuilder.build();

        return this.httpClient.post(url, changedEntries);
    }

    public getProjects() {
        const urlBuilder = new ApiUrlBuilder(`/api/settings/projects`);
        const url = urlBuilder.build();

        return this.httpClient.get<{ projects: Record<string, string> }>(url);
    }

    public getLanguages(project: string) {
        const urlBuilder = new ApiUrlBuilder(`/api/settings/languages`);
        urlBuilder.setParameter('project', project);

        return this.httpClient.get<{
            userLanguages: Record<string, string>,
            projectLanguages: Record<string, string>,
            sourceLanguage: string
        }>(urlBuilder.build());
    }

    public getSystemLanguages() {
        const urlBuilder = new ApiUrlBuilder(`/api/settings/systemLanguages`);

        return this.httpClient.get<Record<string, string>>(urlBuilder.build());
    }

    public async authenticate(credentials: IUserCredentials) {
        let urlBuilder = new ApiUrlBuilder(`/api/auth`);

        const url = urlBuilder.build();

        return this.httpClient.post<string>(url, credentials);
    }

    public async getUser() {
        let urlBuilder = new ApiUrlBuilder(`/api/users`);

        const url = urlBuilder.build();

        return this.httpClient.get<IUser>(url);
    }

    public async addKey(request: IKeyCreationRequest) {
        let urlBuilder = new ApiUrlBuilder(`/api/keys/add`);

        const url = urlBuilder.build();

        return this.httpClient.post(url, request);
    }

    public async updateKey(request: IKeyUpdateRequest) {
        let urlBuilder = new ApiUrlBuilder(`api/keys/update`);

        const url = urlBuilder
            .build();

        return this.httpClient.put(url, request);
    }

    public async listKeys(project: string): Promise<{ key: number, value: string }[]> {
        let urlBuilder = new ApiUrlBuilder(`api/keys/list`);

        const url = urlBuilder
            .setParameter("project", project)
            .build();

        const response = await this.httpClient.get<{ key: number, value: string }[]>(url, undefined);

        return response.data;
    }

    public async listAppVersions(project: string): Promise<{ key: number, value: string }[]> {
        let urlBuilder = new ApiUrlBuilder(`api/appversions/list`);

        const url = urlBuilder
            .setParameter("project", project)
            .build();

        const response = await this.httpClient.get<{ item1: number, item2: string }[]>(url, undefined);

        return response.data.map(x => ({
            key: x.item1,
            value: x.item2
        }));
    }

    public async getPopulateOptions(project: string, sourceValue: string): Promise<Record<number, ILocalizationTranslation[]>> {
        let urlBuilder = new ApiUrlBuilder(`api/populate/options`);

        const url = urlBuilder
            .setParameter("project", project)
            .setParameter("value", sourceValue)
            .build();

        const response = await this.httpClient.get(url, undefined);

        return response.data;
    }

    public async copyTranslations(sourceId: number, targetId: number, languageCode: string | undefined = undefined, overwrite: boolean = false) {
        const urlBuilder = new ApiUrlBuilder('api/copy/get');

        if (languageCode) {
            urlBuilder.setParameter("languageCode", languageCode);
        }

        if (overwrite) {
            urlBuilder.setParameter("overwrite", true.toString());
        }

        const url = urlBuilder
            .setParameter("sourceId", sourceId.toString())
            .setParameter("targetId", targetId.toString())
            .build();

        const response = await this.httpClient.get(url);

        return response.data as ITranslationEntryUpdate[];
    }

    public async getEntriesByIds(ids: number[]): Promise<ITranslationEntry[]> {
        let urlBuilder = new ApiUrlBuilder(`api/localization/entries/ids`);

        const url = urlBuilder.build();

        const response = await this.httpClient.post(url, ids, this.axiosConfig);

        return response.data as ITranslationEntry[];
    }

    public async translateKey(id: number, key: string, languageCode: string | undefined = undefined): Promise<ITranslationEntryUpdate[]> {
        let urlBuilder = new ApiUrlBuilder(`api/values/translate`)
            .setParameter("id", id.toString())
            .setParameter("key", key);

        if (languageCode) {
            urlBuilder.setParameter("languageCode", languageCode);
        }

        const url = urlBuilder.build();

        const response = await this.httpClient.post(url, {}, this.axiosConfig);

        const apiResponse = response.data as IApiResponse<ITranslationEntryUpdate[]>;

        if (!apiResponse.success) {
            throw Error(`Api Response does not indicate success: ${apiResponse.message}`);
        }

        return apiResponse.result!;
    }
}
