import {useContentLoading} from "../hooks/useContentLoading";
import {useContentError} from "../hooks/useContentError";
import style from "./main-section.module.scss";
import {EntryList} from "../../features/translations/components/entry-list/EntryList";
import React from "react";
import {AppLoading} from "./AppLoading";

export const MainSection = () => {
    const [contentLoading] = useContentLoading();
    const [contentError] = useContentError();

    return (
        <section className={style.container}>
            {contentLoading
                ? <AppLoading/>
                : contentError
                    ? <p className={style.error}>{contentError}</p>
                    : <EntryList/>
            }
        </section>
    )
}
