import {useEditTagsDialog} from "../hooks/useEditTagsDialog";
import {DialogContainer} from "../../dialogs/components/DialogContainer";
import {DialogCloseButton} from "../../dialogs/components/DialogCloseButton";
import {DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import {LocalizationKey} from "../../translations/components/entry/LocalizationKey";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {ITranslationEntry} from "../../../shared/models/ITranslationEntry";
import {useTranslationTags} from "../hooks/useTranslationTags";
import {TagSelector} from "./TagSelector";

export const EditTagsDialog = () => {
    const {onShowSubscription, ...context} = useEditTagsDialog();

    const entries = useAppSelector(state => state.translations.entries);
    const [entry, setEntry] = useState<ITranslationEntry>();

    const tagSettings = useAppSelector(state => state.settings.tags);
    const languages = useAppSelector(state => state.settings.user.languages);

    const [[keyTags, languageTags], onChange, onToggle] = useTranslationTags(entry?.id || 0, entry?.tagStates);

    useEffect(() =>
        onShowSubscription(({keyId}) => {
            setEntry(entries.filter(e => e.id === keyId)[0]);
        }), [entries, onShowSubscription]);

    const renderContent = () => {
        if (!entry || !tagSettings) {
            return null;
        }

        return (
            <Grid container spacing={1.5} columns={16}>
                <Grid item xs={16}>
                    <LocalizationKey text={entry.key}/>

                    <TagSelector tags={keyTags}
                                 onChange={tagNames => onChange(tagNames)}
                                 onToggle={tagName => onToggle(tagName)}
                    />
                </Grid>

                {Object.keys(languages).map(languageCode => (
                    <Grid item xs={8} key={languageCode}>
                        <Typography color='primary'>
                            <small>{languages[languageCode]}</small>
                        </Typography>

                        <TagSelector tags={languageTags[languageCode]}
                                     onChange={tagNames => onChange(tagNames, languageCode)}
                                     onToggle={tagName => onToggle(tagName, languageCode)}/>
                    </Grid>
                ))}
            </Grid>
        )
    }

    return (
        <DialogContainer dialogName={context.dialogName}>
            <DialogTitle>Edit Tags</DialogTitle>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent dividers>
                {renderContent()}
            </DialogContent>
        </DialogContainer>
    )
}
