import {Environment} from "../../../shared/utils/environment";
import {LogHandler} from "../LogHandler";
import {ConsoleLogger} from "../ConsoleLogger";
import {RemoteLogger} from "../RemoteLogger";
import {useEffect} from "react";

export const useLogger = () => {
    useEffect(() => {
        Environment.isDevelopment() && LogHandler.Loggers.push(new ConsoleLogger());
        LogHandler.Loggers.push(new RemoteLogger());
    }, []);
}
