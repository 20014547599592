import {TextPreview} from "../text-preview/TextPreview";
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {BasicSetupOptions, Extension, useCodeMirror, ViewUpdate} from "@uiw/react-codemirror";
import {html} from "@codemirror/lang-html";
import {EditorView} from '@codemirror/view';
import {quietlightInit} from "@uiw/codemirror-theme-quietlight";
import curlyBracketHighlight from "./curlyBracketHighlight";

type TextEditorProps = {
    value: string
    onChange?: (value: string) => void
    onBlur?: () => void
    readOnly?: boolean
    outlineColor?: string
    maxLength?: number
    placeholder?: string
    className?: string
    minHeight?: string
    maxHeight?: string
    height?: string
    width?: string
    minWidth?: string
    maxWidth?: string
}

const baseExtensions = [
    html({autoCloseTags: false}),
    curlyBracketHighlight(),
    EditorView.lineWrapping,
    EditorView.theme({
        '&': {
            fontSize: '14px',
            border: 'solid 1px rgba(47, 79, 79, 0.4)'
        },
        '&.cm-focused': {
            outline: 'none'
        }
    })
];

const theme = quietlightInit({
    settings: {
        background: '#f9f9f9',
        fontFamily: 'monospace'
    }
});

const basicSetup: BasicSetupOptions = {
    lineNumbers: false,
    autocompletion: false,
    allowMultipleSelections: false,
    indentOnInput: false,
    foldGutter: false,
    drawSelection: false,
    highlightActiveLine: false,
    highlightActiveLineGutter: false,
    highlightSelectionMatches: false,
    dropCursor: false,
    rectangularSelection: false,
    crosshairCursor: false,
    bracketMatching: true
};

export const TextEditor = ({
                               value,
                               onChange,
                               onBlur,
                               readOnly,
                               outlineColor,
                               // TODO: Max Length
                               maxLength,
                               placeholder,
                               className,
                               minHeight = '30px',
                               maxHeight = '250px',
                               height,
                               minWidth,
                               maxWidth,
                               width
                           }: TextEditorProps) => {
    const editorRef = useRef(null);
    const onChangeRef = useRef(onChange);
    const onBlurRef = useRef(onBlur);

    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    useEffect(() => {
        onBlurRef.current = onBlur;
    }, [onBlur]);

    const [extraExtensions, setExtraExtensions] = useState<Extension[]>([]);

    const onChangeCallback = useCallback((value: string, viewUpdate: ViewUpdate) => {
        const onChange = onChangeRef.current;
        return onChange && viewUpdate.docChanged && onChange(value);
    }, []);

    const onUpdateCallback = useCallback((viewUpdate: ViewUpdate) => {
        const onBlur = onBlurRef.current;
        return onBlur && viewUpdate.focusChanged && !viewUpdate.view.hasFocus && onBlur();
    }, []);

    const {setContainer} = useCodeMirror({
        container: editorRef.current,
        extensions: [...baseExtensions, ...extraExtensions],
        value,
        theme,
        indentWithTab: false,
        minHeight,
        maxHeight,
        height,
        minWidth,
        maxWidth,
        width,
        basicSetup,
        placeholder,
        className,
        readOnly,
        editable: !readOnly,
        onChange: onChangeCallback,
        onUpdate: onUpdateCallback
    });

    const [shown, setShown] = useState(false);

    useLayoutEffect(() => {
        shown && setContainer(editorRef.current!);
    }, [setContainer, shown]);

    useLayoutEffect(() => {
        const extraExtensions: Extension[] = [];

        outlineColor && extraExtensions.push(EditorView.theme({
            '&': {
                boxShadow: '0px 0px 5px 1px ' + outlineColor
            }
        }));

        setExtraExtensions(extraExtensions);
    }, [outlineColor]);

    return (
        <TextPreview value={value}
                     width={width}
                     height={height}
                     maxWidth={maxWidth}
                     maxHeight={maxHeight}
                     minWidth={minWidth}
                     minHeight={minHeight}
                     placeholder={placeholder}
                     onShow={() => setShown(true)}>
            <div ref={editorRef}/>
        </TextPreview>
    )
}
