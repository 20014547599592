import {batch, HandleThunkActionCreator} from "react-redux";
import {Dispatch} from "redux";
import {resetView} from "../../reducers/viewReducer";
import {resetTranslations} from "../../reducers/translationsReducer";
import {resetSettings} from "../../reducers/settingsReducer";
import {userSlice} from "../../reducers/userReducer";

export const signOut = (): HandleThunkActionCreator<any> => (dispatch: Dispatch) => {
    batch(() => {
        dispatch(userSlice.actions.signOut());
        dispatch(resetView());
        dispatch(resetTranslations());
        dispatch(resetSettings());
    });
};
