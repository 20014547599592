import {ITranslationEntryUpdate} from "../../../shared/models/ITranslationEntryUpdate";
import React, {useMemo} from "react";
import {useTranslationTags} from "../../tags/hooks/useTranslationTags";
import {ITranslationEntry} from "../../../shared/models/ITranslationEntry";
import {Divider, IconButton, Paper, Stack, Typography} from "@mui/material";
import style from "./changes-dialog.module.scss";
import {LocalizationKey} from "../../translations/components/entry/LocalizationKey";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {firstUpper} from "../../../shared/utils/strings/firstUpper";
import {PopulateMode} from "../../../shared/models/populateMode";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactDiffViewer from "react-diff-viewer-continued";
import {useAppDispatch} from "../../../app/hooks/useAppDispatch";
import {TagDiff} from "./TagDiff";
import {resetTranslationChanges} from "../../../app/reducers/changesReducer";

type KeyChangeItemProps = {
    keyId: number
    translationChanges: ITranslationEntryUpdate[]
    entry: ITranslationEntry
}

const getOldValue = (source: ITranslationEntry, changed: ITranslationEntryUpdate) => {
    return source?.values[changed.languageCode]?.value || '';
};

export const KeyChangeItem = ({keyId, translationChanges, entry}: KeyChangeItemProps) => {
    const dispatch = useAppDispatch();
    const languages = useAppSelector(state => state.settings.system.languages);
    const projects = useAppSelector(state => state.settings.user.projects);

    const [[keyTags, languageTags]] = useTranslationTags(keyId, entry.tagStates);

    const changedKeyTags = useMemo(() => keyTags.filter(t => t.changed), [keyTags]);

    const changedLanguageCodes = useMemo(() => {
        const tagLanguageCodes = Object.keys(languageTags);

        return [
            ...new Set([
                ...translationChanges?.map(x => x.languageCode) || [],
                ...tagLanguageCodes.filter(x => languageTags[x].some(y => y.changed))
            ])
        ].sort((a, b) => (languages[a] || a).localeCompare(languages[b] || b))
    }, [languageTags, languages, translationChanges]);

    const renderHeader = () => (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <LocalizationKey text={entry.key}/>
            <Typography color={'darkslategrey'}>
                <strong>{projects[entry.project] || entry.project}</strong>
            </Typography>
        </Stack>
    )

    const renderTranslationChange = (changedEntry: ITranslationEntryUpdate) => (
        <React.Fragment>
            <Stack direction={'row'}
                   justifyContent={'space-between'}
                   alignItems={'center'}>

                <Typography color={'gray'}>
                    <small>{firstUpper(PopulateMode[changedEntry.populateMode])}</small>
                </Typography>

                <IconButton onClick={() => dispatch(resetTranslationChanges({entry: changedEntry}))}
                            sx={{'&:hover': {color: 'indianred'}}}
                            size="small">
                    <DeleteIcon fontSize="inherit"/>
                </IconButton>
            </Stack>

            <div className={style.diff}>
                <ReactDiffViewer
                    oldValue={getOldValue(entry, changedEntry)}
                    newValue={changedEntry.value}
                    splitView={false}
                    hideLineNumbers={true}
                />
            </div>
        </React.Fragment>
    )

    return (
        <Paper variant={'outlined'}
               sx={{
                   padding: 2
               }}>

            <Stack direction={'column'}
                   spacing={1.5}
                   divider={<Divider orientation="horizontal" flexItem/>}>
                {renderHeader()}

                {changedKeyTags.length &&
                    <Stack spacing={.5} padding={.5}>
                        {changedKeyTags.map(t => <TagDiff key={`${keyId}_${t.name}`}
                                                          keyId={keyId}
                                                          tagState={t}/>)}
                    </Stack>
                }

                {changedLanguageCodes.map(languageCode => {
                    const translationChange = translationChanges?.filter(x => x.languageCode === languageCode)?.[0];
                    const tagChanges = languageTags[languageCode]?.filter(t => t.changed) || [];

                    return (
                        <Stack key={languageCode} spacing={1}>
                            <Typography color={'primary'}>
                                <strong>
                                    <small>{languages[languageCode]}</small>
                                </strong>
                            </Typography>

                            {!!tagChanges.length &&
                                <Stack spacing={.5}>
                                    {tagChanges.map(t => <TagDiff key={`${keyId}_${languageCode}_${t.name}`}
                                                                  keyId={keyId}
                                                                  tagState={t}
                                                                  languageCode={languageCode}/>)}
                                </Stack>}

                            {translationChange &&
                                <Stack spacing={.5}>
                                    {renderTranslationChange(translationChange)}
                                </Stack>}
                        </Stack>
                    )
                })}
            </Stack>
        </Paper>
    )
}
