import style from "./app-loading.module.scss";
import React from "react";
import {CircularProgress} from "@mui/material";

export const AppLoading = () => {
    return (
        <div className={style.container}>
            <CircularProgress className={style.loading}/>
        </div>
    )
}
