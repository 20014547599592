export const normalizeObject = <TV>(obj: Record<string, TV>): Record<string, TV> =>
    obj && Object
        .entries(obj)
        .sort((a, b) => String(a[1]).localeCompare(String(b[1])))
        .reduce((result, entry) => {
            const normalizedKey = entry[0].toLowerCase();
            if (result.hasOwnProperty(normalizedKey)) {
                throw new Error(`Property '${normalizedKey}' already exists in the object`)
            }
            result[normalizedKey] = entry[1];
            return result;
        }, {} as Record<string, TV>)
