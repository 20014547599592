import {Environment} from "../../utils/environment";

const PRODUCTION_URL = "https://api-l.xmonetize.net";
const ALPHA_URL = "https://localization-alpha-api.stage.testik.org";
const BETA_URL = "https://localization-beta-api.stage.testik.org";
const DEBUG_URL = "http://localhost:41052";

export class ApiUrlProvider {
    public static get(): string {
        if (Environment.isProduction()) {
            return PRODUCTION_URL;
        } else if (Environment.isAlpha()) {
            return ALPHA_URL;
        } else if (Environment.isBeta()) {
            return BETA_URL;
        } else {
            return DEBUG_URL;
        }
    }
}
