import {useCallback} from "react";
import {ITranslationEntryTagState} from "../../../shared/models/ITranslationEntryTagState";
import {useTagStatusProvider} from "../../tags/hooks/useTagStatusProvider";
import {TranslationTagStatus} from "../../../shared/models/TranslationTagStatus";
import {useTranslationTags} from "../../tags/hooks/useTranslationTags";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {TagNames} from "../../tags/TagNames";

export const useTranslationCheckActions = (keyId: number, languageCode?: string, tagStates?: ITranslationEntryTagState[]): [() => void, () => void] => {
    const languages = useAppSelector(x => x.settings.user.languages);
    const tagStatusProvider = useTagStatusProvider(keyId, tagStates);
    const [[keyTags], , , switchTagStatus] = useTranslationTags(keyId, tagStates);

    const tryAcceptLanguage = useCallback((languageCode: string) => {
        if (tagStatusProvider(languageCode)[TagNames.ToCheck]?.status === TranslationTagStatus.Active) {
            switchTagStatus(TagNames.ToCheck, TranslationTagStatus.Deleted, languageCode);

            if (keyTags.filter(x => x.name === TagNames.UpdateRequired)[0]?.status === TranslationTagStatus.Active) {
                switchTagStatus(TagNames.UpdateRequired, TranslationTagStatus.Inactive, languageCode);
            } else {
                switchTagStatus(TagNames.UpdateRequired, TranslationTagStatus.Deleted, languageCode);
            }
        }
    }, [keyTags, switchTagStatus, tagStatusProvider]);

    const tryRejectLanguage = useCallback((languageCode: string) => {
        if (tagStatusProvider(languageCode)[TagNames.ToCheck]?.status === TranslationTagStatus.Active) {
            switchTagStatus(TagNames.ToCheck, TranslationTagStatus.Deleted, languageCode);
            switchTagStatus(TagNames.UpdateRequired, TranslationTagStatus.Active, languageCode);
        }
    }, [switchTagStatus, tagStatusProvider]);

    const accept = useCallback(() => {
        if (languageCode) {
            tryAcceptLanguage(languageCode);
        } else {
            for (const languageCode of Object.keys(languages)) {
                tryAcceptLanguage(languageCode);
            }
        }
    }, [languageCode, languages, tryAcceptLanguage]);

    const reject = useCallback(() => {
        if (languageCode) {
            tryRejectLanguage(languageCode);
        } else {
            for (const languageCode of Object.keys(languages)) {
                tryRejectLanguage(languageCode);
            }
        }
    }, [languageCode, languages, tryRejectLanguage]);

    return [accept, reject];
}
