import React, {useEffect} from 'react';
import {useAppSelector} from "../hooks/useAppSelector";
import {useUnsavedChangesPrompt} from "../hooks/useUnsavedChangesPrompt";
import {AppLoading} from "./AppLoading";
import {AuthSection} from "../../features/auth-section/components/AuthSection";
import {Footer} from './Footer';
import {Header} from './Header';
import {useErrorAlert} from "../hooks/useErrorAlert";
import {useAuth} from "../hooks/useAuth";
import {getSettings} from "../thunks/settings/getSettings";
import {useLogger} from "../../features/logging/hooks/useLogger";
import {MainSection} from "./MainSection";
import {AppDialogs} from "./AppDialogs";
import {useAppDispatch} from "../hooks/useAppDispatch";
import {useTranslationsRequestWatcher} from "../hooks/useTranslationsRequestWatcher";

export const App = () => {
    const dispatch = useAppDispatch();

    const {
        authenticated,
        authorized,
        loading: authLoading
    } = useAuth();

    useEffect(() => {
        authorized && dispatch(getSettings());
    }, [dispatch, authorized]);

    useLogger();
    useUnsavedChangesPrompt();
    useErrorAlert();
    useTranslationsRequestWatcher();

    const renderApp = () =>
        (
            <React.Fragment>
                <Header/>
                <MainSection/>
                <Footer/>
                <AppDialogs/>
            </React.Fragment>
        )

    const appLoading = useAppSelector(state => state.settings.isLoading || state.user.isLoading);

    return (
        <React.Fragment>
            {appLoading || authLoading
                ? <AppLoading/>
                : authenticated
                    ? renderApp()
                    : <AuthSection/>}
        </React.Fragment>
    )
}
