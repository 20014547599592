import * as React from "react";
import style from "./entry.module.scss";
import {ITranslationEntry} from "../../../../shared/models/ITranslationEntry";
import {KeyDescription} from "../key-description/KeyDescription";
import {useAppSelector} from "../../../../app/hooks/useAppSelector";
import {EntryCollapsibleContent} from "./EntryCollapsibleContent";
import {useTranslationChange} from "../../hooks/useTranslationChange";
import {useUserRole} from "../../../../app/hooks/useUserRole";
import {KeyAccessLevel} from "../../../../shared/models/keyAccessLevel";
import {EntryTranslation} from "./EntryTranslation";
import {LocalizationKey} from "./LocalizationKey";
import {TranslationTags} from "../../../tags/components/TranslationTags";
import {Chip, Stack} from "@mui/material";
import {UserRole} from "../../../../shared/models/userRole";
import {EntryActions} from "./EntryActions";
import {ViewMode} from "../../../../shared/models/viewMode";
import {useTagStatusProvider} from "../../../tags/hooks/useTagStatusProvider";
import {TranslationTagStatus} from "../../../../shared/models/TranslationTagStatus";
import {TagNames} from "../../../tags/TagNames";

type EntryProps = {
    entry: ITranslationEntry
}

export const Entry = ({entry}: EntryProps) => {
    const sourceLanguage = useAppSelector(state => state.settings.user.sourceLanguage);
    const targetLanguage = useAppSelector(state => state.view.language);
    const languages = useAppSelector(state => state.settings.user.languages);
    const viewMode = useAppSelector(state => state.view.viewMode);

    const {currentValue: sourceValue} = useTranslationChange(entry, sourceLanguage);
    const tagStatusProvider = useTagStatusProvider(entry.id, entry.tagStates);
    const [userRole] = useUserRole();

    return (
        <div className={style.entry}>
            <Stack gap={'10px'}>
                <div className={style.header}>
                    <LocalizationKey title={`#${entry.id}\nClick once to copy.\nCreated ${entry.createdOn} by ${entry.createdBy}`}
                                     text={entry.key}/>

                    {userRole === UserRole.Admin &&
                        <EntryActions entry={entry} sourceValue={sourceValue} targetLanguage={targetLanguage}/>}
                </div>

                <TranslationTags keyId={entry.id} tagStates={entry.tagStates}/>

                {entry.originalAppVersion &&
                    <Stack direction='row'
                           gap={.5}
                           flexWrap='wrap'>
                        <Chip size={'small'}
                              label={entry.originalAppVersion}
                              sx={{
                                  width: '55px',
                                  fontSize: '9px',
                                  fontWeight: '500',
                                  '&.MuiChip-root': {
                                      height: 15
                                  }
                              }}

                        />
                    </Stack>
                }

                <KeyDescription content={entry.description}/>

                <div className={style.headTextAreas}>
                    <EntryTranslation entry={entry}
                                      languageCode={sourceLanguage}
                                      className={style.sourceLanguage}
                                      tools={
                                          <TranslationTags keyId={entry.id}
                                                           languageCode={sourceLanguage}
                                                           tagStates={entry.tagStates}/>
                                      }/>

                    <EntryTranslation entry={entry}
                                      languageCode={targetLanguage}
                                      className={style.targetLanguage}
                                      tools={
                                          <TranslationTags keyId={entry.id}
                                                           languageCode={targetLanguage}
                                                           tagStates={entry.tagStates}/>
                                      }/>
                </div>

                <div className={style.footerToolbox}>
                    <div className={style.leftFooterToolbox}>
                        {entry.translationLengthLimit > 0 &&
                            <span className={style.limit}>Max Length: {entry.translationLengthLimit}</span>}
                    </div>

                    <div className={style.rightFooterToolbox}>
                        {userRole === UserRole.Admin &&
                            <span>{KeyAccessLevel[entry.keyAccessLevel].toString()}</span>}
                    </div>
                </div>

                <EntryCollapsibleContent languageCodes={Object.keys(languages).filter(l => {
                    if (l === sourceLanguage) {
                        return false;
                    }

                    if (viewMode === ViewMode.ToCheck) {
                        return tagStatusProvider(l)[TagNames.ToCheck]?.originalStatus === TranslationTagStatus.Active;
                    }

                    return true;
                })}
                                         renderTranslation={(className, lang) =>
                                             <EntryTranslation key={`${entry.id}_${lang}`}
                                                               entry={entry}
                                                               languageCode={lang}
                                                               className={className}
                                                               tools={
                                                                   <TranslationTags keyId={entry.id}
                                                                                    languageCode={lang}
                                                                                    tagStates={entry.tagStates}/>
                                                               }/>}
                />
            </Stack>
        </div>
    )
}
