import React from "react";
import {Children} from "../../../shared/types";
import {Dialog, styled, Theme, useMediaQuery, useTheme} from "@mui/material";
import {DialogName} from "../types/dialogName";
import {useDialogContext} from "../hooks/useDialogContext";
import {SxProps} from "@mui/system";

type DialogProps<TDialogData> = {
    dialogName: DialogName
} & DialogViewProps<TDialogData>

type DialogViewProps<T> = {
        sx?: SxProps<Theme>
        fullScreen?: boolean
    }
    & ({ children?: Children, render?: undefined } | { children?: undefined, render: (data?: T) => Children })

const StyledDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        maxWidth: 950
    }
}));

export function DialogContainer<TDialogData>({dialogName, children, render, ...props}: DialogProps<TDialogData>) {
    const context = useDialogContext<TDialogData>(dialogName);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const renderBody = () => (
        children ? children : render ? render() : null
    )

    return (
        <StyledDialog open={context.hasShown()}
                      onClose={() => context.hide()}
                      fullScreen={fullScreen || props.fullScreen}
                      fullWidth
                      {...props}>
            {renderBody()}
        </StyledDialog>
    )
}
