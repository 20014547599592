import {useEffect, useRef} from "react";

export const useIsFirstRender = () => {
    const isFirstRender = useRef<boolean>(true);

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    return isFirstRender;
}
