import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {useCallback} from "react";
import {TagViewState} from "./tagViewState";
import {TranslationTagScope} from "../../../shared/models/TranslationTagScope";
import {getTagChangeId} from "../getTagChangeId";
import {ITranslationEntryTagState} from "../../../shared/models/ITranslationEntryTagState";
import {ITranslationTagMetadata} from "../../../shared/models/ITranslationTagMetadata";

const filterTagStates = (state: ITranslationEntryTagState, tag: ITranslationTagMetadata, keyId: number, languageCode?: string) => {
    if (languageCode) {
        return state.tagName === tag.name
            && state.keyId === keyId
            && state.languageStatuses
            && state.languageStatuses.hasOwnProperty(languageCode);
    } else {
        return state.tagName === tag.name
            && state.keyId === keyId;
    }
}

const getStatus = (tag: ITranslationTagMetadata, keyId: number, states?: ITranslationEntryTagState[], languageCode?: string) => {
    if (!states) {
        return undefined;
    }

    if (languageCode) {
        return states
            .filter(s => filterTagStates(s, tag, keyId, languageCode))
            .map(s => s.languageStatuses![languageCode])
            ?.[0];
    } else {
        return states
            .filter(s => filterTagStates(s, tag, keyId))
            .map(s => s.keyStatus)
            ?.[0];
    }
}

export const useTagViewStateProvider = () => {
    const tags = useAppSelector(state => state.settings.tags.tagsMetadata);
    const changes = useAppSelector(state => state.changes.tagChanges);

    return useCallback((keyId: number, tagStates?: ITranslationEntryTagState[], languageCode?: string) => {
        const result: TagViewState[] = [];

        if (!keyId || !tags?.length) {
            return result;
        }

        const getChangeIdEx = (tagName: string) => getTagChangeId({tagName: tagName, keyId, languageCode});

        const requiredScope = languageCode ? TranslationTagScope.Language : TranslationTagScope.Key;

        for (const tag of tags.filter(t => t.scopes.includes(requiredScope))) {
            const changeId = getChangeIdEx(tag.name);

            const originalStatus = getStatus(tag, keyId, tagStates, languageCode);

            const status = changes.hasOwnProperty(changeId)
                ? changes[changeId].status
                : originalStatus;

            result.push({
                status,
                originalStatus,
                changed: status !== originalStatus,
                ...tag
            });
        }

        return result;
    }, [changes, tags]);
}
