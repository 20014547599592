export class RecordUtils {
    public static containsKey<TKey extends string | number, TValue>(source: Record<TKey, TValue>, key: TKey): boolean {
        return Object.keys(source).includes(String(key));
    }

    public static firstOrDefaultKey<T>(source: Record<string, T>, predicate?: (value: string) => boolean): string {
        if (!predicate) {
            return Object.keys(source)[0];
        }

        return Object.keys(source).filter(key => predicate!(key))[0];
    }
}
