import {useAppSelector} from "./useAppSelector";
import {useEffect} from "react";
import {clearChangesError} from "../reducers/changesReducer";
import {useAppDispatch} from "./useAppDispatch";
import {useErrorAlertDialog} from "../../features/error-alert/hooks/useErrorAlertDialog";
import {clearSettingsError} from "../reducers/settingsReducer";

export const useErrorAlert = () => {
    const dispatch = useAppDispatch();
    const errorAlertContext = useErrorAlertDialog();
    const changesError = useAppSelector(state => state.changes.errorMessage);
    const settingsError = useAppSelector(state => state.settings.errorMessage);

    useEffect(() => errorAlertContext.onShowSubscription(x => {
        if (x.source === 'changes') {
            dispatch(clearChangesError());
        } else if (x.source === 'settings') {
            dispatch(clearSettingsError());
        }
    }), [dispatch, errorAlertContext]);

    useEffect(() => {
        if (changesError) {
            errorAlertContext.show({message: changesError, source: 'changes'});
        } else if (settingsError) {
            errorAlertContext.show({message: settingsError, source: 'settings'})
        }
    }, [changesError, errorAlertContext, settingsError]);
}
