import {DialogName} from "../types/dialogName";
import {useDialogs} from "./useDialogs";
import {useMemo} from "react";
import {useSubscription} from "../../../shared/reactive/useSubscription";
import {Unsubscribe} from "../../../shared/reactive/unsubscribe";
import {SubscriberCallback} from "../../../shared/reactive/subscriberCallback";

type DialogContextState<TDialogData> = {
    dialogName: DialogName
    hasShown: () => boolean
    show: (data: TDialogData) => void
    hide: () => void
    getData: () => TDialogData
    onShowSubscription: (callback: SubscriberCallback<TDialogData>) => Unsubscribe
    onHideSubscription: (callback: SubscriberCallback<TDialogData>) => Unsubscribe
}

export const useDialogContext = <TDialogData = void>(dialogName: DialogName): DialogContextState<TDialogData> => {
    const dialogs = useDialogs();

    const subscribers = dialogs.getSubscribers(dialogName);
    const [onShowSubscription, onShow] = useSubscription<TDialogData>(subscribers.onShowSubscribers);
    const [onHideSubscription, onHide] = useSubscription<TDialogData>(subscribers.onHideSubscribers);

    return useMemo(() => {
        const hasShown = () => dialogs.hasShown(dialogName);

        const show = (data: TDialogData) => {
            dialogs.show<TDialogData>({name: dialogName, data});
            onShow(data);
        };

        const hide = () => {
            const data = getData();
            dialogs.hide(dialogName) && onHide(data);
        };
        const getData = () => dialogs.getData(dialogName);

        return {
            dialogName,
            show,
            hasShown,
            hide,
            getData,
            onShowSubscription,
            onHideSubscription
        };
    }, [dialogName, dialogs, onHide, onHideSubscription, onShow, onShowSubscription]);
};
