import {createAsyncThunk} from "@reduxjs/toolkit";
import {Query} from "../../../shared/utils/query";
import {LocalStorage} from "../../../shared/utils/localStorage";
import {RootState} from "../../store/types";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {normalizeObject} from "../../../shared/utils/normalizeObject";
import {RecordUtils} from "../../../shared/utils/recordUtils";

type ChangeProjectProps = {
    selectedProject: string
}

export const changeProject = createAsyncThunk('view/changeProject', async ({selectedProject}: ChangeProjectProps, {getState}) => {
    const state = getState() as RootState;
    const apiClient = new LocalizationClient(state.user.jwt);

    const languagesResponse = await apiClient.getLanguages(selectedProject);
    const [userLanguages, projectLanguages, sourceLanguage] = [
        normalizeObject(languagesResponse.data.userLanguages),
        normalizeObject(languagesResponse.data.projectLanguages),
        languagesResponse.data.sourceLanguage.toLowerCase()
    ];

    // TODO: Refactor
    const persistedLanguage = (Query.getLanguage() || LocalStorage.getSelectedLanguage())?.toLowerCase();
    const selectedLanguage = RecordUtils.containsKey(userLanguages, persistedLanguage) && persistedLanguage !== sourceLanguage
        ? persistedLanguage
        : RecordUtils.firstOrDefaultKey(userLanguages, value => value !== sourceLanguage);

    Query.setLanguage(selectedLanguage);
    LocalStorage.saveSelectedLanguage(selectedLanguage);
    Query.setProject(selectedProject);
    LocalStorage.saveSelectedProject(selectedProject);

    return {
        selectedProject,
        selectedLanguage,
        userLanguages,
        projectLanguages,
        sourceLanguage
    };
});
