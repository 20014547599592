import {createAsyncThunk} from "@reduxjs/toolkit";
import {IUserCredentials} from "../../../shared/models/IUserCredentials";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {AxiosError} from "axios";

type AuthenticateThunkProps = {
    credentials: IUserCredentials
}

export const authenticate = createAsyncThunk('user/authenticate', async ({credentials}: AuthenticateThunkProps) => {
    if (!credentials.username) {
        throw new Error('You must specify a username');
    }

    const apiClient = new LocalizationClient();

    try {
        return (await apiClient.authenticate(credentials)).data;
    } catch (e) {
        const axiosError = e as AxiosError;

        if (axiosError?.response && axiosError.response.status === 401) {
            throw new Error('Bad credentials')
        }

        throw e;
    }
});
