import {UrlBuilder} from "./UrlBuilder";
import {RecordUtils} from "../../utils/recordUtils";

export class ApiUrlBuilder extends UrlBuilder {
    public setParameter(name: string, value?: string): this {
        if (!value) {
            return this;
        }

        const parameterExists = RecordUtils.containsKey(this.parameters, name);

        if (!parameterExists && value) {
            this.parameters[name] = value!;
        }

        return this;
    }
}
