import {Autocomplete, Chip, FormControl, TextField} from "@mui/material";
import {TagViewState} from "../hooks/tagViewState";
import React, {useMemo} from "react";
import {TranslationTagStatus} from "../../../shared/models/TranslationTagStatus";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

type TagSelectorProps = {
    tags: TagViewState[]
    onChange?: (tagNames: string[]) => void
    onToggle?: (tagName: string) => void
}

function normalizeArray<T>(value: T | T[]): T[] {
    return Array.isArray(value) ? value : [value];
}

const tagSorter = (a: TagViewState, b: TagViewState) => {
    const groupCompareResult = a.group.localeCompare(b.group);
    if (groupCompareResult) {
        return groupCompareResult;
    }

    return a.name.localeCompare(b.name);
}

export const TagSelector = ({tags, onChange, onToggle}: TagSelectorProps) => {
    const viewableTags = useMemo(() =>
            tags
                .filter(t => t.status === TranslationTagStatus.Active || t.status === TranslationTagStatus.Inactive)
                .sort(tagSorter),
        [tags]);

    const sortedTags = useMemo(() => tags.sort(tagSorter), [tags]);

    return (
        <FormControl fullWidth>
            <Autocomplete
                multiple
                value={viewableTags}
                disableClearable
                options={sortedTags}
                groupBy={option => option.group}
                getOptionLabel={option => option.displayName}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                renderInput={(params) => (
                    <TextField {...params}
                               placeholder='Add tags'
                               size='small'
                               onKeyDown={(event: any) => {
                                   if (event.key === 'Backspace' || event.key === 'Delete') {
                                       event.stopPropagation()
                                   }
                               }}/>
                )}
                renderTags={(value: readonly TagViewState[], getTagProps) =>
                    value.map((option: TagViewState, index: number) => (
                        <Chip label={option.displayName}
                              clickable
                              size={'small'}
                              {...getTagProps({index})}
                              sx={{maxWidth: '150px', height: 20, fontSize: 10}}
                              onClick={() => onToggle && onToggle(option.name)}
                              onDelete={() => onToggle && onToggle(option.name)}
                              color={(option.status === TranslationTagStatus.Inactive ? 'error' : 'primary')}
                              deleteIcon={(option.status === TranslationTagStatus.Inactive
                                  ? <ToggleOffIcon/>
                                  : <ToggleOnIcon/>)}
                        />
                    ))
                }
                onChange={(_, value) => {
                    onChange && onChange(normalizeArray(value).map(x => x.name));
                }}
            />
        </FormControl>
    )
}
