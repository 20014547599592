import {createAsyncThunk} from "@reduxjs/toolkit";
import {IKeyCreationRequest} from "../../../shared/models/IKeyCreationRequest";
import {RootState} from "../../store/types";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {getTranslations} from "../translations/getTranslations";

type AddKeyThunkProps = {
    request: IKeyCreationRequest
}

export const addKey = createAsyncThunk('changes/addKey', async ({request}: AddKeyThunkProps, {getState, dispatch}) => {
    const state = getState() as RootState;

    const client = new LocalizationClient(state.user.jwt);
    await client.addKey(request);

    // TODO: Highlight new key
    dispatch(getTranslations());
});
