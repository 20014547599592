import {LOCALSTORAGE_KEYS} from "../constants";

export class LocalStorage {
    public static getJwtBearerToken(): string | undefined {
        const token = localStorage.getItem(LOCALSTORAGE_KEYS.AUTH.JWT_TOKEN) as string;

        if (!token) {
            return undefined;
        }

        return token;
    }

    public static saveJwtBearerToken(token: string) {
        localStorage.setItem(LOCALSTORAGE_KEYS.AUTH.JWT_TOKEN, token);
    }

    public static removeJwtBearerToken() {
        localStorage.removeItem(LOCALSTORAGE_KEYS.AUTH.JWT_TOKEN);
    }

    public static saveSelectedProject(project: string) {
        localStorage.setItem(LOCALSTORAGE_KEYS.SETTINGS.PROJECT, project);
    }

    public static getSelectedProject(): string {
        return localStorage.getItem(LOCALSTORAGE_KEYS.SETTINGS.PROJECT) as string;
    }

    public static saveSelectedLanguage(language: string) {
        localStorage.setItem(LOCALSTORAGE_KEYS.SETTINGS.LANGUAGE, language);
    }

    public static getSelectedLanguage(): string {
        return localStorage.getItem(LOCALSTORAGE_KEYS.SETTINGS.LANGUAGE) as string;
    }
}
