import {default as React, useLayoutEffect, useRef, useState} from "react";
import style from "./text-preview.module.css";
import {Children} from "../../../../shared/types";

type TextPreviewProps = {
    value: string
    children: Children
    onShow?: () => void
    placeholder?: string
    minHeight?: string
    maxHeight?: string
    height?: string
    width?: string
    minWidth?: string
    maxWidth?: string
}

export const TextPreview = ({value, children, onShow, ...props}: TextPreviewProps) => {
    const [inPreviewMode, setInPreviewMode] = useState(true);
    const intersectionObserverRef = useRef<IntersectionObserver>();
    const previewRef = useRef(null);

    useLayoutEffect(() => {
        if (!inPreviewMode) {
            onShow && onShow();
        }
    }, [inPreviewMode, onShow]);

    useLayoutEffect(() => {
        if (!inPreviewMode) {
            return;
        }

        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            try {
                if (inPreviewMode) {
                    entries.forEach((e: IntersectionObserverEntry) => e.isIntersecting && e.intersectionRatio === 1 && setTimeout(() => setInPreviewMode(false), Math.random() * 100));
                }
            } catch (e) {
                console.error("can't handle intersection", e);
            }
        }

        try {
            const intersectionObserver = new IntersectionObserver(handleIntersection, {
                root: null,
                rootMargin: '0px',
                threshold: 1
            });
            intersectionObserver.observe(previewRef.current! as Element);
            intersectionObserverRef.current = intersectionObserver;
        } catch (e) {
            console.error("can't use observer", e)
        }

        return () => {
            try {
                const intersectionObserver = intersectionObserverRef.current;
                intersectionObserver && intersectionObserver.disconnect();
            } catch (e) {
                console.error("can't disconnect observer", e)
            }
        }
    }, [inPreviewMode]);

    return (
        <React.Fragment>
            {inPreviewMode
                ? <pre className={style.preview}
                       ref={previewRef}
                       style={{
                           ...props
                       }}
                       onClick={() => setInPreviewMode(false)}>
                    {value}
                </pre>
                : children}
        </React.Fragment>
    )
}
