import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {ITranslationEntryUpdate} from "../../../shared/models/ITranslationEntryUpdate";
import {useCallback, useLayoutEffect, useState} from "react";
import {changeEntries, resetTranslationChanges} from "../../../app/reducers/changesReducer";
import {PopulateMode} from "../../../shared/models/populateMode";
import {ITranslationEntry} from "../../../shared/models/ITranslationEntry";
import {useAppDispatch} from "../../../app/hooks/useAppDispatch";

const selectChange = (changes: Record<string, ITranslationEntryUpdate>, id: number, languageCode: string) => {
    for (const value of Object.values(changes)) {
        if (id === value.id && languageCode === value.languageCode) {
            return value;
        }
    }

    return undefined;
}

const coalesce = (str1: string | undefined, str2: string) => str1 !== undefined ? str1 : str2;

const useCommittedValue = (entry: ITranslationEntry, languageCode: string): [string, boolean] => {
    const change = useAppSelector(state => selectChange(state.changes.changes, entry.id, languageCode));
    return change !== undefined
        ? [change.value, true]
        : [entry.values[languageCode]?.value || '', false];
}

const useAutoCommit = (commit: () => void) =>
    useLayoutEffect(() => {
        const timeoutId = window.setTimeout(() => commit(), 250);
        return () => window.clearTimeout(timeoutId);
    }, [commit]);

export const useTranslationChange = (entry: ITranslationEntry, languageCode: string) => {
    const dispatch = useAppDispatch();

    const [localValue, setLocalValue] = useState<string>();
    const onChange = useCallback((value: string) => setLocalValue(value), []);

    const originalValue = entry.values[languageCode]?.value || '';
    const [committedValue, changed] = useCommittedValue(entry, languageCode);

    const commit = useCallback(() => {
        if (localValue == null) {
            return;
        }

        if (originalValue === localValue) {
            dispatch(resetTranslationChanges({entry: {id: entry.id, languageCode}}));
        } else {
            dispatch(changeEntries({
                updates: {
                    id: entry.id,
                    key: entry.key,
                    languageCode: languageCode,
                    project: entry.project,
                    value: localValue,
                    populateMode: PopulateMode.manual
                }
            }));
        }
    }, [dispatch, entry.id, entry.key, entry.project, languageCode, localValue, originalValue]);

    useAutoCommit(commit);

    useLayoutEffect(() => {
        if (localValue == null) {
            return;
        }

        if (localValue === committedValue) {
            setLocalValue(undefined);
        }
    }, [committedValue, localValue]);

    return {
        currentValue: coalesce(localValue, committedValue),
        onChange,
        commit,
        changed
    };
}
