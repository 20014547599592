import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../store/types";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {ITranslationEntriesRequest} from "../../../shared/models/ITranslationEntriesRequest";
import {Pagination} from "../../../shared/utils/pagination";
import {SortDirection} from "../../../shared/models/sortDirection";

export const getTranslations = createAsyncThunk('translations/loadTranslations', async (_, {getState}) => {
    const state = getState() as RootState;

    const apiClient = new LocalizationClient(state.user.jwt);

    const request: ITranslationEntriesRequest = {
        project: state.view.project,
        searchQuery: state.view.searchQuery,
        offset: Pagination.getOffset(state.view.page, state.translations.totalCount, state.view.pageSize),
        limit: state.view.pageSize,
        searchMode: state.view.searchOptions.searchMode,
        sortDirection: SortDirection.Ascending,
        viewMode: state.view.viewMode,
        targetLanguage: state.view.language,
        sourceLanguage: state.settings.user.sourceLanguage
    };

    const response = await apiClient.getEntries(request);
    return response.data;
});
