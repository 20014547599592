import {IViewState} from "../states/IViewState";
import {ViewMode} from "../../shared/models/viewMode";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISearchOptions} from "../../shared/models/ISearchOptions";
import {getTranslations} from "../thunks/translations/getTranslations";
import {Pagination} from "../../shared/utils/pagination";
import {getSettings} from "../thunks/settings/getSettings";
import {changeProject} from "../thunks/view/changeProject";
import {SearchMode} from "../../shared/models/searchMode";

const initialState: IViewState = {
    project: "",
    language: "",
    viewableEntryIds: [],
    searchQuery: "",
    viewMode: ViewMode.All,
    pageSize: 30,
    pageCount: 0,
    page: 1,
    searchOptions: {
        searchMode: SearchMode.Everywhere
    }
};

export const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        search: (state, action: PayloadAction<string | undefined>) => {
            state.searchQuery = action.payload || '';
            state.page = 1;
        },
        changeLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
        changeProject: (state, action: PayloadAction<string>) => {
            state.project = action.payload;
            state.page = 1;
        },
        changePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        changeViewMode: (state, action: PayloadAction<ViewMode>) => {
            state.viewMode = action.payload;
            state.page = 1;
        },
        changePageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload;
        },
        changeSearchOptions: (state, action: PayloadAction<ISearchOptions>) => {
            state.searchOptions = action.payload;
        },
        resetView: (state) => {
            state.page = 1;
            state.pageCount = 0;
            state.searchQuery = '';
            state.searchOptions = {searchMode: SearchMode.Everywhere};
            state.viewableEntryIds = [];
            state.highlightedEntryId = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getTranslations.fulfilled, (state, action) => {
                state.viewableEntryIds = action.payload.entries.map(e => e.id);
                const pageCount = Pagination.getPageCount(action.payload.totalEntryCount, state.pageSize);
                state.pageCount = pageCount;

                if (state.page > pageCount) {
                    state.page = 1;
                }
            })
            .addCase(getSettings.fulfilled, (state, action) => {
                const viewSettings = action.payload.view;

                if (viewSettings.project) {
                    state.project = viewSettings.project;
                }

                if (viewSettings.language) {
                    state.language = viewSettings.language;
                }

                if (viewSettings.searchOptions) {
                    state.searchOptions = viewSettings.searchOptions;
                }

                if (viewSettings.searchQuery) {
                    state.searchQuery = viewSettings.searchQuery;
                }

                state.viewMode = viewSettings.viewMode;
            })
            .addCase(changeProject.fulfilled, (state, action) => {
                state.project = action.payload.selectedProject;
                state.language = action.payload.selectedLanguage;
                state.page = 1;
            })
    }
});

export const {
    resetView,
    changePageSize,
    changePage,
    changeViewMode,
    changeLanguage,
    search,
    changeSearchOptions
} = viewSlice.actions;

export default viewSlice.reducer;
