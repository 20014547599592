import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../store/types";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {LogHandler} from "../../../features/logging/LogHandler";
import {AxiosUtils} from "../../../shared/utils/axiosUtils";
import {AxiosError} from "axios";
import {getTranslations} from "../translations/getTranslations";

export const saveChanges = createAsyncThunk('changes/saveChanges', async (_, {getState, dispatch}) => {
    const state = getState() as RootState;
    const client = new LocalizationClient(state.user.jwt);

    const translationChanges = state.changes.changes;

    if (Object.keys(translationChanges).length) {
        try {
            await client.saveTranslationChanges(Object.values(translationChanges));
        } catch (e) {
            const errorResponse = AxiosUtils.createErrorResponse(e as AxiosError);

            LogHandler.fatal("saveChanges", "failed to save translation changes", {errorResponse});

            throw new Error(errorResponse.message, {
                cause: 'translation'
            });
        }
    }

    const tagChanges = state.changes.tagChanges;

    if (Object.keys(tagChanges).length) {
        try {
            await client.switchTagStatus(Object.values(tagChanges));
        } catch (e) {
            const errorResponse = AxiosUtils.createErrorResponse(e as AxiosError);

            LogHandler.fatal("saveChanges", "failed to save tag changes", {errorResponse});

            throw new Error(errorResponse.message);
        }
    }

    // TODO: Handle different errors

    dispatch(getTranslations());
});
