import {ISettingsState} from "../states/ISettingsState";
import {createSlice} from "@reduxjs/toolkit";
import {getSettings} from "../thunks/settings/getSettings";
import {changeProject} from "../thunks/view/changeProject";

const initialState: ISettingsState = {
    system: {
        languages: {}
    },
    user: {
        projects: {},
        languages: {},
        sourceLanguage: "",
        viewModes: [],
        allLanguagesAvailable: false
    },
    tags: {
        tagsMetadata: []
    },
    project: {
        languages: {}
    },
    isLoading: false,
    languagesLoading: false,
    hasLoaded: false
}

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        resetSettings: state => {
            state.hasLoaded = false;
            state.user = initialState.user;
            state.tags = initialState.tags;
            state.isLoading = false;
        },
        clearError: state => {
            state.errorMessage = undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getSettings.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = undefined;
                state.hasLoaded = false;
            })
            .addCase(getSettings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = {
                    ...action.payload.user
                };
                state.tags = {
                    tagsMetadata: action.payload.tagsMetadata
                };
                state.system = {
                    ...action.payload.system
                };
                state.project = {
                    ...action.payload.project
                };
                state.hasLoaded = true;
            })
            .addCase(getSettings.rejected, (state, action) => {
                state.isLoading = false;
                state.errorMessage = action.error.message;
            })
            .addCase(changeProject.pending, state => {
                state.languagesLoading = true;
            })
            .addCase(changeProject.rejected, (state, action) => {
                state.languagesLoading = false;
                state.errorMessage = action.error.message;
            })
            .addCase(changeProject.fulfilled, (state, action) => {
                state.languagesLoading = false;
                state.user.sourceLanguage = action.payload.sourceLanguage;
                state.user.languages = action.payload.userLanguages;
                state.project.languages = action.payload.projectLanguages;
            })
    }
})

export const {
    resetSettings,
    clearError: clearSettingsError
} = settingsSlice.actions;

export default settingsSlice.reducer;

