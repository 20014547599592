import {createAsyncThunk} from "@reduxjs/toolkit";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {AxiosError} from "axios";
import {signOut} from "./signOut";

type AuthorizeThunkProps = {
    jwtToken?: string
}
export const authorize = createAsyncThunk('user/authorize', async ({jwtToken}: AuthorizeThunkProps, {getState, dispatch}) => {
    if (!jwtToken) {
        throw new Error('Not authenticated');
    }

    const apiClient = new LocalizationClient(jwtToken);

    try {
        const response = await apiClient.getUser();

        return {user: response.data, jwtToken};
    } catch (e) {
        const axiosError = e as AxiosError;

        if (axiosError?.response && axiosError.response.status === 401) {
            dispatch(signOut());

            throw new Error('Authentication required');
        }

        throw e;
    }
});
