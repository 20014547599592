import {Decoration, EditorView, ViewPlugin} from '@codemirror/view';
import {ViewUpdate} from "@uiw/react-codemirror";

const simpleBracketsPlugin = ViewPlugin.fromClass(class {
    decorations;

    constructor(view: EditorView) {
        this.decorations = this.getBracketDecorations(view);
    }

    update(update: ViewUpdate) {
        if (update.docChanged || update.selectionSet || update.viewportChanged) {
            this.decorations = this.getBracketDecorations(update.view);
        }
    }

    getBracketDecorations(view: EditorView) {
        const {doc} = view.state;
        const decorations = [];
        let openBracketPos = null;

        for (let pos = 0; pos < doc.length; ++pos) {
            const char = doc.sliceString(pos, pos + 1);

            if (char === '{') {
                openBracketPos = pos;
            } else if (char === '}' && openBracketPos !== null) {
                decorations.push(
                    Decoration.mark({class: 'curly-bracket'}).range(openBracketPos, pos + 1),
                );
                openBracketPos = null;
            }
        }

        decorations.sort((a, b) => a.from - b.from || a.to - b.to);

        return Decoration.set(decorations);
    }
}, {
    decorations: v => v.decorations,
});

export default function curlyBracketHighlight() {
    return [
        simpleBracketsPlugin,
        EditorView.baseTheme({
            '.curly-bracket': {backgroundColor: '#FDFD96', color: '#FF6961'},
            '.curly-bracket > span': {backgroundColor: '#FDFD96', color: '#FF6961'},
        }),
    ];
}
