import {ViewMode} from "../models/viewMode";
import {SearchMode} from "../models/searchMode";

export class Query {
    public static get(key: string): string | undefined {
        return new URLSearchParams(global.location.search).get(key) as string;
    }

    public static set(key: string, value: string | undefined): void {
        if (!global.history.replaceState) {
            return;
        }

        const url = new URL(global.location.href);
        const params = new URLSearchParams(global.location.search);

        if (!value) {
            params.delete(key);
        } else {
            params.set(key, value);
        }

        url.search = params.toString();

        global.history.replaceState({
            url: url.toString()
        }, "", url.toString());
    }

    public static getViewMode(): ViewMode | undefined {
        const mode = Query.get("m");

        if (!mode) {
            return undefined;
        }

        switch (mode.toLowerCase()) {
            case "all":
                return ViewMode.All;
            case "untranslated":
                return ViewMode.ToTranslate;
            case "empty":
                return ViewMode.Empty;
            case "recent":
                return ViewMode.Recent;
            case "populate":
                return ViewMode.Populate;
            case "upper":
                return ViewMode.UpperCase;
            case "check":
                return ViewMode.ToCheck;
            default:
                return ViewMode.All;
        }
    }

    public static setViewMode(viewMode?: ViewMode): void {
        if (!viewMode) {
            Query.set("m", undefined);
        }

        switch (viewMode) {
            case ViewMode.All:
                Query.set("m", "all");
                break;
            case ViewMode.ToTranslate:
                Query.set("m", "untranslated");
                break;
            case ViewMode.Empty:
                Query.set("m", "empty");
                break;
            case ViewMode.Recent:
                Query.set("m", "recent");
                break;
            case ViewMode.Populate:
                Query.set("m", "populate");
                break;
            case ViewMode.UpperCase:
                Query.set("m", "upper");
                break;
            case ViewMode.ToCheck:
                Query.set("m", "check");
                break;
            default:
                Query.set("m", undefined);
                break;
        }
    }

    public static getProject(): string | undefined {
        const project = Query.get("p");

        return project ? project.toLowerCase() : undefined;
    }

    public static setProject(project: string | undefined) {
        Query.set("p", project);
    }

    public static getLanguage(): string | undefined {
        const language = Query.get("l");

        return language ? language.toLowerCase() : undefined;
    }

    public static setLanguage(language: string | undefined) {
        Query.set("l", language);
    }

    public static getSearchQuery(): string | undefined {
        const searchQuery = Query.get("s");

        return searchQuery ? searchQuery : undefined;
    }

    public static setSearchQuery(searchQuery: string | undefined) {
        Query.set("s", searchQuery);
    }

    public static getSearchMode(): SearchMode {
        const value = Query.get("sm");

        if (!value) {
            return SearchMode.Everywhere;
        }

        switch (value.toLowerCase()) {
            case "key":
                return SearchMode.Keys;
            case "tran":
                return SearchMode.Translations;
            case "appver":
                return SearchMode.AppVersions;
            default:
                return SearchMode.Everywhere;
        }
    }

    public static setSearchMode(searchMode?: SearchMode): void {
        if (searchMode === SearchMode.Keys) {
            Query.set("sm", "key");
        } else if (searchMode === SearchMode.Translations) {
            Query.set("sm", "tran");
        } else if (searchMode === SearchMode.AppVersions) {
            Query.set("sm", "appver")
        } else {
            Query.set("sm", undefined);
        }
    }
}
