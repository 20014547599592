import * as React from "react";
import {ChangeEvent} from "react";
import style from "./footer.module.scss";
import {batch} from "react-redux";
import {useAppSelector} from "../hooks/useAppSelector";
import {changePage, changePageSize} from "../reducers/viewReducer";
import {FormControl, IconButton, NativeSelect, Pagination, useMediaQuery, useTheme} from "@mui/material";
import {useContentLoading} from "../hooks/useContentLoading";
import {useAppDispatch} from "../hooks/useAppDispatch";
import {UserRole} from "../../shared/models/userRole";
import {useUserRole} from "../hooks/useUserRole";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {classCombine} from "../../shared/utils/classCombine";
import {useExportDataDialog} from "../../features/export-data/hooks/useExportDataDialog";

export const Footer = () => {
    const dispatch = useAppDispatch();
    const pageSize = useAppSelector(state => state.view.pageSize);
    const pageCount = useAppSelector(state => state.view.pageCount);
    const activePage = useAppSelector(state => state.view.page);
    const [contentLoading] = useContentLoading();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [userRole] = useUserRole();
    const exportDialog = useExportDataDialog();

    const onPageChange = (_: any, page: number) => {
        dispatch(changePage(Number(page)));
    };

    const onPageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        batch(() => {
            dispatch(changePageSize(Number(event.target.value)));
            dispatch(changePage(1));
        });
    }

    const renderLeftBlock = () => (
        <div className={classCombine(style.sideBlock, style.leftBlock)}>
            {userRole === UserRole.Admin && <IconButton size={'small'} onClick={() => exportDialog.show({})}><SaveAltIcon/></IconButton>}
        </div>
    )

    const renderRightBlock = () => (
        <div className={classCombine(style.sideBlock, style.rightBlock)}>
            <FormControl>
                <NativeSelect value={pageSize}
                              size={"small"}
                              variant='standard'
                              disabled={contentLoading}
                              onChange={onPageSizeChange}>
                    <option key='epp_15' value={15}>15</option>
                    <option key='epp_30' value={30}>30</option>
                    <option key='epp_50' value={50}>50</option>
                    <option key='epp_100' value={100}>100</option>
                </NativeSelect>
            </FormControl>
        </div>
    )

    const renderPagination = () => {
        if (pageCount < 1 || activePage < 1) {
            return null;
        }

        return (
            <Pagination count={pageCount}
                        page={activePage}
                        shape="rounded"
                        size={isMobile ? 'small' : 'medium'}
                        siblingCount={1}
                        boundaryCount={1}
                        disabled={contentLoading}
                        onChange={onPageChange}></Pagination>
        )
    }

    return (
        <footer className={style.footer}>
            {renderLeftBlock()}
            {renderPagination()}
            {renderRightBlock()}
        </footer>
    )
}

