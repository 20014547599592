import {createAsyncThunk} from "@reduxjs/toolkit";
import {LocalizationClient} from "../../../shared/api/services/LocalizationClient";
import {RootState} from "../../store/types";
import {ITranslationEntryUpdate} from "../../../shared/models/ITranslationEntryUpdate";
import {changeEntries} from "../../reducers/changesReducer";

type TranslateKeyProps = {
    id: number
    key: string
    languageCode?: string
}

export const translateKey = createAsyncThunk('translations/translate', async ({id, key, languageCode}: TranslateKeyProps, {getState, dispatch}) => {
    const state = getState() as RootState;
    const client = new LocalizationClient(state.user.jwt);

    const values = await client.translateKey(id, key, languageCode);

    const updates: ITranslationEntryUpdate[] = [];
    for (const value of values) {
        if (!state.translations.entries.some(entry => entry.id === value.id && entry.values?.[value.languageCode]?.value?.length)) {
            updates.push(value);
        }
    }

    dispatch(changeEntries({updates}));

    return values;
})
