import {Environment} from "../../utils/environment";

export abstract class UrlBuilder {
    protected readonly baseUrl: string;
    protected readonly parameters: Record<string, string>;
    protected readonly arrayParameters: Record<string, string[]>;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
        this.parameters = {};
        this.arrayParameters = {};
    }

    public build(): string {
        const params: string[] = [];

        Object.keys(this.parameters).map(p => params.push(`${encodeURIComponent(p)}=${encodeURIComponent(this.parameters[p])}`));

        Object.keys(this.arrayParameters).map(p => this.arrayParameters[p].map(x=> params.push(`${encodeURIComponent(p)}=${encodeURIComponent(x)}`)));

        const url = `${this.baseUrl}${params.length > 0 ? '?' : ''}${params.join("&")}`;

        Environment.isDevelopment() && console.log(url);

        return url;
    }
}
