import {ILogRequest} from "./ILogRequest";

export class LogRequestFactory {
    public static debug(category: string, message: string, trackingObject?: any): ILogRequest {
        return {
            category: category,
            message: message,
            trackingObject: trackingObject,
            logLevel: "Debug"
        }
    }

    public static info(category: string, message: string, trackingObject?: any): ILogRequest {
        return {
            category: category,
            message: message,
            trackingObject: trackingObject,
            logLevel: "Info"
        }
    }

    public static warn(category: string, message: string, trackingObject?: any): ILogRequest {
        return {
            category: category,
            message: message,
            trackingObject: trackingObject,
            logLevel: "Warn"
        }
    }

    public static error(category: string, message: string, trackingObject?: any): ILogRequest {
        return {
            category: category,
            message: message,
            trackingObject: trackingObject,
            logLevel: "Error"
        }
    }

    public static fatal(category: string, message: string, trackingObject?: any): ILogRequest {
        return {
            category: category,
            message: message,
            trackingObject: trackingObject,
            logLevel: "Fatal"
        }
    }
}
