import {LocalizationClient} from "../../shared/api/services/LocalizationClient";
import {ILogRequest} from "./ILogRequest";
import {ILogger} from "./ILogger";
import {IApiResponse} from "../../shared/models/IApiResponse";
import {store} from "../../app/store/store";

export class RemoteLogger implements ILogger {
    private readonly categoryPrefix: string = "UI";

    async log(request: ILogRequest): Promise<void> {
        try {
            const client = new LocalizationClient(store.getState().user.jwt);

            const responseRaw = await client.log({
                ...request,
                category: `${this.categoryPrefix}_${request.category}`,
            });

            const response = responseRaw.data as IApiResponse<any>;

            if (!response || !response.success) {
                throw Error("unsuccessful response")
            }
        } catch (e) {
            console.error("can't log via remote logger", e);
        }
    }
}
