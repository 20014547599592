import {createSlice} from "@reduxjs/toolkit";
import {ITranslationsState} from "../states/ITranslationsState";
import {getTranslations} from "../thunks/translations/getTranslations";
import {translateKey} from "../thunks/translations/translateKey";

const initialState: ITranslationsState = {
    entries: [],
    totalCount: 0,
    isLoading: false
}

export const translationsSlice = createSlice({
    name: 'translations',
    initialState,
    reducers: {
        resetTranslations: (state) => {
            state.entries = [];
            state.totalCount = 0;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getTranslations.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = undefined;
                state.entries = [];
            })
            .addCase(getTranslations.fulfilled, (state, action) => {
                state.entries = action.payload.entries;
                state.totalCount = action.payload.totalEntryCount;
                state.isLoading = false;
            })
            .addCase(getTranslations.rejected, (state, action) => {
                state.isLoading = false;
                state.errorMessage = action.error.message;
                state.totalCount = 0;
            })
            .addCase(translateKey.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(translateKey.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(translateKey.rejected, (state) => {
                state.isLoading = false;
            })
    }
});

export const {
    resetTranslations
} = translationsSlice.actions;

export default translationsSlice.reducer;
