import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../../store/types";
import {TranslationTagStatus} from "../../../shared/models/TranslationTagStatus";
import {firstUpper} from "../../../shared/utils/strings/firstUpper";
import {ITranslationEntryUpdate} from "../../../shared/models/ITranslationEntryUpdate";
import {changeEntries} from "../../reducers/changesReducer";
import {PopulateMode} from "../../../shared/models/populateMode";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useMemo} from "react";
import {useUserRole} from "../../hooks/useUserRole";
import {UserRole} from "../../../shared/models/userRole";

export const useNormalizeCaseAvailable = () => {
    const [userRole] = useUserRole();
    const entries = useAppSelector(state => state.translations.entries);

    return useMemo(() => {
        if (userRole !== UserRole.Admin) {
            return false;
        }

        return entries?.some(e => {
            if (!e.tagStates?.some(x => x.tagName === 'upper_case' && x.keyStatus === TranslationTagStatus.Active)) {
                return false;
            }

            return Object.values(e.values)
                .map(v => v.value)
                .some(v => v?.length
                    && v.toUpperCase() !== v.toLowerCase()
                    && v.toUpperCase() === v);
        })
    }, [entries, userRole]);
}

export const normalizeCase = createAsyncThunk('translations/normalizeCase', async (_, {getState, dispatch}) => {
    const state = getState() as RootState;

    const updates: ITranslationEntryUpdate[] = [];

    for (const entry of state.translations.entries) {
        if (!entry.tagStates?.some(x => x.tagName === 'upper_case' && x.keyStatus === TranslationTagStatus.Active)) {
            continue;
        }

        for (const languageCode of Object.keys(entry.values)) {
            const value = entry.values[languageCode].value;

            if (value?.length
                && value.toUpperCase() !== value.toLowerCase()
                && value.toUpperCase() === value) {
                const normalizedValue = firstUpper(value.toLowerCase());

                updates.push({
                    ...entry,
                    value: normalizedValue,
                    languageCode: languageCode,
                    populateMode: PopulateMode.manual
                })
            }
        }
    }

    if (updates.length) {
        dispatch(changeEntries({updates}));
    }
})
