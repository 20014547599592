import React from 'react';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {store} from "./app/store/store";
import {App} from './app/components/App';
import {createRoot} from "react-dom/client";
import {DialogProvider} from './features/dialogs/components/DialogProvider';
import {CssBaseline} from "@mui/material";
import {BrowserRouter} from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <Provider store={store}>
        <DialogProvider>
            <CssBaseline/>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </DialogProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
