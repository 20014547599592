export class Pagination {
    public static getPageCount(entryCount: number, entriesPerPage: number): number {
        return Math.ceil(entryCount / entriesPerPage);
    }

    public static getOffset(pageID: number, entryCount: number, entriesPerPage: number): number {
        const pageCount = this.getPageCount(entryCount, entriesPerPage);

        if (pageID > pageCount) {
            pageID = pageCount;
        }

        return pageCount === 0 ? 0 : (pageID - 1) * entriesPerPage;
    }
}