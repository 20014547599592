import React, {useEffect, useMemo, useRef} from "react";
import {LocalizationClient} from "../services/LocalizationClient";
import {useAppSelector} from "../../../app/hooks/useAppSelector";

const clientFactory = (jwtToken?: string) => new LocalizationClient(jwtToken);

export const useApiClient = (): React.MutableRefObject<LocalizationClient> => {
    const jwt = useAppSelector(s => s.user.jwt);

    const apiClient = useMemo(() => clientFactory(jwt), [jwt]);
    const apiClientRef = useRef<LocalizationClient>(apiClient);

    useEffect(() => {
        apiClientRef.current = apiClient;
    }, [apiClient]);

    return apiClientRef;
}
