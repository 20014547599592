import {Chip, Stack, Typography} from "@mui/material";
import {TranslationTagStatus} from "../../../shared/models/TranslationTagStatus";
import React, {useMemo} from "react";
import {ITranslationEntryTagState} from "../../../shared/models/ITranslationEntryTagState";
import {useTagViewStateProvider} from "../hooks/useTagViewStateProvider";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {ChipProps} from "@mui/material/Chip/Chip";
import {useUserRole} from "../../../app/hooks/useUserRole";
import {UserRole} from "../../../shared/models/userRole";
import {groupBy} from "../../../shared/utils/groupBy";
import {useTranslationTags} from "../hooks/useTranslationTags";
import DeleteIcon from "@mui/icons-material/Delete";

type TranslationTagsProps = {
    keyId: number
    tagStates?: ITranslationEntryTagState[]
    languageCode?: string
}

type TagChipContext = { chipProps: ChipProps, status?: TranslationTagStatus, name: string, languageCode?: string }

const getChipColor = (status?: TranslationTagStatus) => (
    status === TranslationTagStatus.Active
        ? '#1976d2'
        : status === TranslationTagStatus.Inactive
            ? '#d32f2f'
            : '#333333'
)

export const TranslationTags = ({keyId, tagStates, languageCode}: TranslationTagsProps) => {
    const languages = useAppSelector(state => state.settings.user.languages);

    const viewStateProvider = useTagViewStateProvider();

    const [userRole] = useUserRole();
    const showTags = userRole === UserRole.Admin;

    const [, , onToggle, onSwitch] = useTranslationTags(keyId || 0, tagStates);

    const chipContexts = useMemo(() => {
        const chipContexts: TagChipContext[] = [];

        if (languageCode) {
            viewStateProvider(keyId, tagStates, languageCode)
                .filter(t => t.status != null && t.status !== TranslationTagStatus.Deleted)
                .forEach(t => {
                    const color = getChipColor(t.status);

                    chipContexts.push({
                        chipProps: {
                            key: `${t.name}_${keyId}_${languageCode}`,
                            label: t.displayName,
                            variant: 'outlined',
                            sx: {
                                color: color,
                                borderColor: color
                            },
                        },
                        status: t.status,
                        name: t.name,
                        languageCode: languageCode
                    });
                });
        } else {
            const getViewStates = (languageCode?: string) => viewStateProvider(keyId, tagStates, languageCode)
                .concat(viewStateProvider(keyId, tagStates))
                .filter(t => t.status != null && t.status !== TranslationTagStatus.Deleted)
                .map(t => ({
                    viewState: t,
                    languageCode
                }));

            const tagGroups = groupBy(
                [
                    ...Object
                        .keys(languages)
                        .flatMap(languageCode => getViewStates(languageCode)),
                    ...getViewStates()
                ],
                x => x.viewState.name);

            Object
                .keys(tagGroups)
                .forEach(t => {
                    const group = tagGroups[t];

                    let activeCount = 0;
                    let inactiveCount = 0;

                    for (const languageCode of Object.keys(languages)) {
                        const state = group.filter(x => x.languageCode === languageCode)[0] || group.filter(x => !x.languageCode)[0];

                        if (state) {
                            if (state.viewState.status === TranslationTagStatus.Active) {
                                ++activeCount;
                            } else if (state.viewState.status === TranslationTagStatus.Inactive) {
                                ++inactiveCount;
                            }
                        }
                    }

                    const keyTagStatus = group.filter(x => !x.languageCode)[0]?.viewState?.status;

                    const color = getChipColor(keyTagStatus);
                    const displayName = group[0].viewState.displayName;

                    chipContexts.push({
                        chipProps: {
                            key: `${t}_${keyId}`,
                            label: (
                                <Stack direction={'row'} gap={'5px'}>
                                    <Typography fontSize={'9px'}
                                                fontWeight={500}>
                                        {displayName}
                                    </Typography>

                                    <Stack direction={'row'} gap={'1px'}>
                                        <Typography color={'primary'}
                                                    fontSize={'9px'}
                                                    fontWeight={900}>
                                            {activeCount}
                                        </Typography>

                                        <Typography fontSize={'9px'}
                                                    fontWeight={900}>
                                            /
                                        </Typography>

                                        <Typography color={'error'}
                                                    fontSize={'9px'}
                                                    fontWeight={900}>
                                            {inactiveCount}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            ),
                            variant: 'outlined',
                            sx: {
                                color: color,
                                borderColor: color
                            },
                        },
                        status: keyTagStatus,
                        name: t
                    });
                })
        }

        return chipContexts;
    }, [keyId, languageCode, languages, tagStates, viewStateProvider]);


    const renderChip = (context: TagChipContext) => {
        const interactable = context.status != null && context.status !== TranslationTagStatus.Deleted;

        return <Chip {...context.chipProps}
                     size={'small'}
                     sx={{
                         ...context.chipProps.sx,
                         paddingRight: '2px',
                         paddingLeft: '2px',
                         maxWidth: '130px',
                         fontSize: '9px',
                         fontWeight: '500',
                         position: "relative",
                         userSelect: 'none',
                         height: 20,
                         overflow: "hidden",
                         transition: "0.3s all ease",
                         ".MuiChip-deleteIcon": {
                             position: "absolute",
                             right: "-20px",
                             opacity: "0",
                             transition: "0.3s all ease"
                         },
                         ":hover": {
                             ".MuiChip-deleteIcon": {
                                 right: "4px",
                                 opacity: "1"
                             },
                             "&:has(.MuiChip-deleteIcon)": {
                                 paddingRight: "20px"
                             }
                         }
                     }}
                     onDelete={interactable ? (() => onSwitch && onSwitch(context.name, TranslationTagStatus.Deleted, context.languageCode)) : undefined}
                     deleteIcon={<DeleteIcon/>}
                     clickable={interactable}
                     onClick={interactable ? (e => {
                         if ((e.nativeEvent as PointerEvent)?.pointerType !== 'touch') {
                             onToggle && onToggle(context.name, context.languageCode);
                         }
                     }) : undefined}


        />;
    };

    return (
        <React.Fragment>
            {showTags && !!chipContexts.length &&
                <Stack direction='row'
                       gap={.5}
                       flexWrap='wrap'>
                    {chipContexts.map(renderChip)}
                </Stack>}
        </React.Fragment>
    )
}
