import {useEffect, useState} from "react";
import {LocalStorage} from "../../shared/utils/localStorage";
import {useAppSelector} from "./useAppSelector";
import {authorize} from "../thunks/user/authorize";
import {userSlice} from "../reducers/userReducer";
import {useIsFirstRender} from "../../shared/react/hooks/useIsFirstRender";
import {useAppDispatch} from "./useAppDispatch";

const jwtStorage = {
    get: () => LocalStorage.getJwtBearerToken(),
    update: (token: string) => LocalStorage.saveJwtBearerToken(token),
    remove: () => LocalStorage.removeJwtBearerToken()
}

export const useAuth = () => {
    const dispatch = useAppDispatch();
    const isFirstRender = useIsFirstRender();
    const currentToken = useAppSelector(state => state.user.jwt);
    const authenticated = useAppSelector(state => state.user.authenticated);
    const authorized = useAppSelector(state => state.user.authorized);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const persistedToken = jwtStorage.get();

        if (persistedToken) {
            dispatch(userSlice.actions.setJwt(persistedToken));
        } else {
            setLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        if (currentToken) {
            dispatch(authorize({jwtToken: currentToken}));
            setLoading(false);
        }
    }, [currentToken, dispatch]);

    useEffect(() => {
        if (!isFirstRender.current) {
            if (currentToken) {
                jwtStorage.update(currentToken);
            } else {
                jwtStorage.remove();
            }
        }
    }, [currentToken, isFirstRender]);

    return {authenticated, authorized, loading};
}
