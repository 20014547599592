import React, {useRef, useState} from "react";
import {Children} from "../../../shared/types";
import {DialogsContext} from "../dialogsContext";
import {DialogName} from "../types/dialogName";
import {DialogState} from "../types/dialogState";
import {removeByKey} from "../../../shared/utils/removeByKey";
import {DialogSubscribers} from "../types/dialogSubscribers";

type DialogProviderProps = {
    children?: Children
}

export function DialogProvider({children}: DialogProviderProps) {
    const [dialogs, setDialogs] = useState<Record<DialogName, DialogState<any>>>({} as Record<DialogName, DialogState<any>>)

    const subscribersRef = useRef<Record<DialogName, DialogSubscribers<any>>>({} as Record<DialogName, DialogSubscribers<any>>);

    const show = (state: DialogState<any>) => setDialogs({...dialogs, [state.name]: state});
    const hide = (dialogName: DialogName): boolean => {
        if (hasShown(dialogName)) {
            setDialogs(removeByKey(dialogs, dialogName));
            return true;
        }

        return false;
    };
    const hasShown = (dialogName: DialogName) => dialogs.hasOwnProperty(dialogName);
    const getData = (dialogName: DialogName) => hasShown(dialogName) && dialogs[dialogName].data;

    const getSubscribers = (dialogName: DialogName) => {
        if (subscribersRef.current.hasOwnProperty(dialogName)) {
            return subscribersRef.current[dialogName];
        }

        const subscribers = {onShowSubscribers: [], onHideSubscribers: []};
        subscribersRef.current[dialogName] = subscribers;
        return subscribers;
    }

    return (
        <DialogsContext.Provider value={{dialogs, show, hide, hasShown, getData, getSubscribers}}>
            {children}
        </DialogsContext.Provider>
    )
}
