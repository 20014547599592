import {useEffect, useRef} from "react";
import {useHasUnsavedChanges} from "./useHasUnsavedChanges";

export const useUnsavedChangesPrompt = () => {
    const hasChanges = useHasUnsavedChanges();
    const hasChangesRef = useRef(hasChanges);

    useEffect(() => {
        hasChangesRef.current = hasChanges;
    }, [hasChanges]);

    useEffect(() => {
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (hasChangesRef.current) {
                e = e || window.event;
                // For IE and Firefox prior to version 4
                if (e) {
                    e.returnValue = 'Sure?';
                }
                // For Safari
                return 'Sure?';
            }

            return undefined;
        }

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
        }
    }, []);
}
