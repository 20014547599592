import {useImageAttachmentsDialog} from "../hooks/useImageAttachmentsDialog";
import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import {DialogContainer} from "../../dialogs/components/DialogContainer";
import {Alert, Button, DialogActions, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, ImageListItemBar} from "@mui/material";
import {DialogCloseButton} from "../../dialogs/components/DialogCloseButton";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useApiClient} from "../../../shared/api/hooks/useApiClient";
import {AppLoading} from "../../../app/components/AppLoading";
import {AxiosError} from "axios";

export const ImageAttachmentsDialog = () => {
    const context = useImageAttachmentsDialog();

    const apiClient = useApiClient();

    const [files, setFiles] = useState<File[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>();

    useEffect(() => context.onHideSubscription(() => {
        setError(undefined);
        setIsLoading(false);
        setFiles([]);
    }), [context]);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => setFiles(files.concat(Array.prototype.slice.call(e.target.files)))

    const removeFile = (file: File) => setFiles(files.filter(f => f !== file))

    const removeAllFiles = () => setFiles([])

    const uploadFiles = () => {
        setIsLoading(true);
        setError(undefined);

        apiClient.current.uploadImages(context.getData().prefix, files)
            .then(urls => {
                context.getData().setImageUrls(urls);
                context.hide();
            })
            .catch((e: AxiosError) => setError(e.message))
            .finally(() => setIsLoading(false));
    }

    const imagePreviews = useMemo(() => files.map(f => ({file: f, url: URL.createObjectURL(f)})), [files]);

    return (
        <DialogContainer dialogName={context.dialogName}>
            <DialogTitle>Attach images</DialogTitle>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent dividers style={{minHeight: '100px'}}>
                {isLoading && <AppLoading/>}

                {!imagePreviews.length && <Alert severity="info">Add images to upload</Alert>}

                {!!error?.length && <Alert severity="error">An error occurred: {error}</Alert>}

                {!!imagePreviews.length && !isLoading &&
                    <ImageList gap={10}>
                        {imagePreviews.map(i =>
                            <ImageListItem>
                                <img src={i.url}/>
                                <ImageListItemBar
                                    actionIcon={
                                        <IconButton onClick={() => removeFile(i.file)} color={'primary'}>
                                            <DeleteOutlineIcon/>
                                        </IconButton>
                                    }>
                                </ImageListItemBar>
                            </ImageListItem>
                        )}
                    </ImageList>}
            </DialogContent>
            <DialogActions>
                <Button disabled={!files.length || isLoading} onClick={uploadFiles}>Upload images</Button>
                <IconButton component='label' color='primary' disabled={isLoading}>
                    <AddPhotoAlternateIcon/>
                    <input type='file' multiple hidden accept='image/*' onChange={onFileChange}/>
                </IconButton>

                <IconButton color='primary' onClick={removeAllFiles} disabled={!files.length || isLoading}>
                    <DeleteOutlineIcon/>
                </IconButton>
            </DialogActions>
        </DialogContainer>
    )
}
