import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import style from "./edit-key-dialog.module.scss";
import {KeyAccessLevel} from "../../../shared/models/keyAccessLevel";
import {useAppSelector} from "../../../app/hooks/useAppSelector";
import {DialogContainer} from "../../dialogs/components/DialogContainer";
import {Autocomplete, Button, DialogActions, DialogContent, DialogTitle, FormControl, TextField} from "@mui/material";
import {DialogCloseButton} from "../../dialogs/components/DialogCloseButton";
import {batch} from "react-redux";
import {updateKey} from "../../../app/thunks/changes/updateKey";
import {addKey} from "../../../app/thunks/changes/addKey";
import {TextEditor} from "../../translations/components/text-editor/TextEditor";
import {useEditKeyDialog} from "../hooks/useEditKeyDialog";
import {useAppDispatch} from "../../../app/hooks/useAppDispatch";
import {useKeyOptions} from "../../translations/hooks/useKeyOptions";
import {useImageAttachmentsDialog} from "../hooks/useImageAttachmentsDialog";

const keyAccessLevels = Object
    .keys(KeyAccessLevel)
    .filter(k => typeof KeyAccessLevel[k as any] === "number")
    .map(k => KeyAccessLevel[k as any]);

export const EditKeyDialog = () => {
    const context = useEditKeyDialog();
    const imageAttachmentsDialogContext = useImageAttachmentsDialog();

    const dispatch = useAppDispatch();

    const projects = useAppSelector(state => state.settings.user.projects);
    const currentProject = useAppSelector(state => state.view.project);

    const [project, setProject] = useState<string>(currentProject);
    const [accessLevel, setAccessLevel] = useState<KeyAccessLevel>(KeyAccessLevel.Required);
    const [description, setDescription] = useState<string>('');
    const [key, setKey] = useState<string>('');
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [attachedImageUrls, setAttachedImageUrls] = useState<string[]>([]);

    useEffect(() => context.onShowSubscription(data => {
        setProject(currentProject);

        const currentKey = data.currentKey;

        if (currentKey?.id) {
            setAccessLevel(currentKey.accessLevel);
            setDescription(currentKey.description || '');
            setKey(currentKey.key);
            setReadOnly(true);
        } else {
            setDescription('');
            setKey('');
            setAccessLevel(KeyAccessLevel.Required);
            setReadOnly(false);
        }
    }), [context, currentProject]);

    const onKeyAccessLevelChange = (value: KeyAccessLevel) => setAccessLevel(value);
    const onProjectChange = (value: string) => setProject(value);
    const onKeyChange = (value: string) => setKey(value);
    const onDescriptionChange = (value: string) => setDescription(value);

    const currentKey = context.getData()?.currentKey;

    const keyOptions = useKeyOptions(project, !currentKey?.id);

    const existingKeyChosen = useMemo(() => {
        return !currentKey?.id && keyOptions.some(o => o.label === key);
    }, [currentKey?.id, key, keyOptions]);

    const onFormSubmit = () => {
        batch(() => {
            if (currentKey?.id) {
                dispatch(updateKey({
                    request: {
                        id: currentKey.id,
                        keyAccessLevel: accessLevel,
                        description: description || ''
                    }
                }))
            } else {
                dispatch(addKey({
                    request: {
                        project: project,
                        key: key!,
                        keyAccessLevel: accessLevel!,
                        description: description || ''
                    }
                }));
            }

            context.hide();
        });
    }

    useEffect(() => {
        if (attachedImageUrls?.length) {
            setDescription(
                description
                + '\n\n<!-- IMAGE ATTACHMENTS -->'
                + '\n<div style="display: flex; flex-direction: row; gap: 5px; flex-wrap: wrap;">'
                + attachedImageUrls.map(url =>
                    '\n<div style="overflow: hidden; max-width: 480px; max-height: 640px;">'
                    + `\n<img src="${url}" style="width: 100%; height: auto;"/>`
                    + '\n</div>'
                ).join('')
                + '\n</div>'
                + '\n<!-- IMAGE ATTACHMENTS -->\n\n'
            );

            setAttachedImageUrls([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachedImageUrls]);

    return (
        <DialogContainer dialogName={context.dialogName}>
            <DialogTitle>{currentKey ? 'Edit Key' : 'Add Key'}</DialogTitle>
            <DialogCloseButton dialogName={context.dialogName}/>
            <DialogContent dividers>
                <form className={style.form} onSubmit={e => e.preventDefault()}>
                    <div className={style.group}>
                        <select className={style.select}
                                disabled={readOnly}
                                value={project}
                                onChange={event => onProjectChange(event.target.value)}>
                            {Object.keys(projects).map(p => <option key={p} value={p}>{projects[p]}</option>)}
                        </select>

                        <select className={style.select}
                                value={accessLevel}
                                onChange={event => onKeyAccessLevelChange(Number(event.target.value))}>

                            {keyAccessLevels.map(x => <option key={x} value={x}>{KeyAccessLevel[Number(x)]}</option>)}
                        </select>
                    </div>

                    <FormControl fullWidth>
                        <Autocomplete renderInput={(params) =>
                            <TextField {...params}
                                       label='Key'
                                       onChange={e => onKeyChange(e.target.value)}
                            />}
                                      className={style.input}
                                      freeSolo
                                      placeholder="Key"
                                      value={key}
                                      disabled={readOnly}
                                      groupBy={option => option.group}
                                      onChange={(_, v) => {
                                          if (typeof v === 'string') {
                                              onKeyChange(v);
                                          } else if (v && v.label) {
                                              onKeyChange(v.label);
                                          }
                                      }}
                                      sx={{
                                          '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                  borderColor: existingKeyChosen ? 'red' : undefined
                                              },
                                              '&:hover fieldset': {
                                                  borderColor: existingKeyChosen ? 'red' : undefined
                                              },
                                              '&.Mui-focused fieldset': {
                                                  borderColor: existingKeyChosen ? 'red' : undefined
                                              }
                                          }
                                      }}
                                      options={keyOptions}/>
                    </FormControl>

                    <TextEditor className={style.input}
                                value={description}
                                minHeight={'175px'}
                                placeholder="Description"
                                onChange={onDescriptionChange}
                                maxLength={2048}/>
                </form>
            </DialogContent>

            <DialogActions>
                <Button onClick={onFormSubmit} disabled={!key || !project || existingKeyChosen}>Save</Button>

                <Button onClick={() => imageAttachmentsDialogContext.show({setImageUrls: setAttachedImageUrls, prefix: `${project}_${key}`})}>
                    Attach images
                </Button>
            </DialogActions>
        </DialogContainer>
    )
}
